import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

export default function MonthOrQuarter({ monthOrQuarter, setMonthOrQuarter }) {
    const handleChange = (e) => { setMonthOrQuarter(e.target.value) }
    return (
        <FormControl >
            <RadioGroup
                aria-labelledby="mq-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={monthOrQuarter}
                onChange={handleChange}
                sx={{ display: 'flex', flexDirection: 'row' }}
            >
                <FormControlLabel value="month" control={<Radio />} label="Сараар" />
                <FormControlLabel value="quarter" control={<Radio />} label="Улирлаар" />
            </RadioGroup>
        </FormControl>
    );
}
