import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { scrollStyle, SUPPLIER_ROLES } from '../../../../../utils/constants'
import { isTokenValid } from '../../../../../utils/functions'
import { keys } from 'underscore'

const StyledCell = ({ children, styling }) => <TableCell sx={{ p: '2px', fontSize: '14px', ...styling }}>{children}</TableCell>
const Row = ({ field, label, value }) => {
    const val = field === 'sale_price' 
                    ?   value ? `${value} ₮` : '-'
                    :   field === 'discount'
                            ?   value ? `${value} %` : '-'
                            :   value||'-'
    return <TableRow>
                <StyledCell styling={{ fontWeight: 'bold' }}>{label}</StyledCell>
                <StyledCell>{val}</StyledCell>
            </TableRow>
}

const promoInfo = {
    sale_price: 'Бөөний үнэ',
    sale_qty: 'Бөөний тоо',
    discount: 'Хямдрал',
    discount_expiredate: 'Хямдрал дуусах хугацаа'
}

const productInfoFields = {
    expDate: 'Барааны дуусах хугацаа',
    qty: 'Үлдэгдэл',
    intName: 'Ерөнхий нэршил',
    dose: 'Тун, хэмжээ',
    form: 'Хэлбэр',
    presc: 'Олгох нөхцөл',
    nation: 'Улс',
    mfr: 'Үйлдвэрлэгч',
    master_box_qty: 'Мастер савлагааны тоо',
}

export default function Specs({ product }) {
    const { role } = isTokenValid()
    const productInfoFs = SUPPLIER_ROLES.includes(role)
        ?   { ...productInfoFields }
        :   Object.keys(productInfoFields).reduce((acc, key) => {
                if (key !== 'qty') {
                    acc[key] = productInfoFields[key]
                }
            return acc
    }, {})

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TableContainer
                sx={{ 
                    mt: 1, maxWidth: '450px',
                    maxHeight: '350px', ...scrollStyle,
                    '&::-webkit-scrollbar': { width: 7 },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main' }
                }}
            >
                <Typography sx={{ p: '3px', pl: 0, fontSize: '14px', borderBottom: 1 }}>Барааны мэдээлэл:</Typography>
                <Table>
                    <TableBody>
                        { keys(productInfoFs).map(k => <Row key={`${k}`} field={k} label={productInfoFs[k]} value={product?.[k]} />) }
                    </TableBody>
                </Table>
                <Box sx={{ mt: 1, mb: 1 }} />
                <Typography sx={{ p: '3px', pl: 0, fontSize: '14px', borderBottom: 1 }}>Урамшууллын мэдээлэл:</Typography>
                <Table>
                    <TableBody>
                        { keys(promoInfo).map(k => <Row key={`${k}`} field={k} label={promoInfo[k]} value={product?.[k]} />) }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
