import ImageSlider from "../../common/ImageSlider"
import Navbar from "../../common/Navbar"
import Features from "./Features"
import Footer from "../footer"
import Hero from "./Hero"

const images = [
    {
        url: '/images/img1.jpg',
        link: '/products/1'
    },
    {
        url: '/images/img2.jpg',
        link: '/products/2'
    },
    {
        url: '/images/img3.jpg',
        link: '/products/3'
    }
]

export default function LandingPage() {

    return (
        <div
            style={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                margin: 0,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Navbar />
            <ImageSlider images={images} />
            <Hero />
            <Features />
            <Footer />
        </div>
    )
}
