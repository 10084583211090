import { ShoppingCart } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function FeatureCard({ feature }) {
    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                border: 1,
                borderRadius: 3,
                borderColor: 'primary.main',
                width: '100%',
                backgroundColor: 'white',
            }}
        >
            <Typography 
                sx={{ 
                    position: 'absolute',
                    top: 0,
                    ml: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: 1,
                    borderRadius: 3,
                    borderColor: 'primary.main',
                    p: '2px 6px',
                    fontSize: '16px',
                    backgroundColor: 'white',
                    color: 'secondary.light',
                }}
            >{feature['name']}</Typography>
            <Box sx={{ width: '100%', textAlign: 'center', pt: 3, pb: 0 }}>
                {feature['icon']}
            </Box>
            <Typography
                sx={{
                    p: 4,
                    pt: 1,
                    fontSize: '15px',
                    color: 'secondary.light'
                }}
            >
                {feature['description']}
            </Typography>
        </Box>
    )
}
