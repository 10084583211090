import { isRefreshValid } from '../utils/functions'
import { SUPPLIER_ROLES } from '../utils/constants'
import { Navigate } from 'react-router-dom'

export default function IsValidAdmin({ children }) {
    const { r, isReviewed } = isRefreshValid()
    if (!isReviewed) { return <Navigate to='/home' replace /> }
    const isAdmin = SUPPLIER_ROLES.includes(r)
    return isAdmin ? children : <Navigate to='/' replace />
}
export function IsValid({ children }) {
    const { isReviewed } = isRefreshValid()
    return isReviewed ? children : <Navigate to='/home' replace />
}
export function IsValidR({ children }) {
    const { r } = isRefreshValid()
    if (r !== process.env.REACT_APP_R) { return <Navigate to='/home' replace /> }
    return children
}