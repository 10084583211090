import { BarChart, DeliveryDining, Loyalty, MonetizationOn, People, ShoppingCart } from "@mui/icons-material"
import { Box, Divider, Grid, Typography } from "@mui/material"
import FeatureCard from "./FeatureCard"

const fs = [
    {
        'name': 'Захиалга',
        'description': 'Захиалагч болон нийлүүлэгч нар 24/7 захиалга авах/өгөх, захиалгын түүхээ харах, шүүх',
        'icon': <ShoppingCart sx={{ fontSize: '35px', color: 'primary.light' }} />,
    },
    {
        'name': 'Борлуулалт',
        'description': 'Pharmo аппликейшнээр дамжуулан борлуулалт хийх, борлуулагч тус бүрийн болон нийт борлуулалтын тайлан харах',
        'icon': <MonetizationOn sx={{ fontSize: '35px', color: 'primary.light' }} />,
    },
    {
        'name': 'Түгээлт',
        'description': 'Захиалгуудаа түгээгчид хуваарилах, түгээлтийн жолоочийн байршил болон түгээлтийн нийт явцыг газрын зураг дээр real-time хянах',
        'icon': <DeliveryDining sx={{ fontSize: '35px', color: 'primary.light' }} />,
    },
    {
        'name': 'Хөнгөлөлт, урамшуулал',
        'description': 'Нийт үнийн дүн, төлбөрийн хэлбэрээс хамаарсан эсвэл багц барааны гэх мэт төрөл бүрийн хөнгөлөлт урамшууллын бодлого хэрэгжүүлэх',
        'icon': <Loyalty sx={{ fontSize: '35px', color: 'primary.light' }} />,
    },
    {
        'name': 'Харилцагч',
        'description': 'Харилцагчийн бүртгэл хөтлөх, ангилал үүсгэх, тухайлсан харилцагч нарт чиглэсэн хөнгөлөлт урамшуулал зарлах',
        'icon': <People sx={{ fontSize: '35px', color: 'primary.light' }} />,
    },
    {
        'name': 'Тайлан',
        'description': 'Борлуулалтын, түгээлтийн, харилцагчийн гэх мэт тайлан гаргах, өдөр, сар, улирал, жилээр багцлан харах',
        'icon': <BarChart sx={{ fontSize: '35px', color: 'primary.light' }} />,
    },
]

export default function Features() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '80%', my: 5 }}>
            <Divider sx={{ color: 'secondary.light', my: 5 }}>
                <Typography variant='h5'>Санал болгох боломжууд</Typography>
            </Divider>
            <Grid container columnSpacing={2} rowSpacing={5}>
                {fs.map((feature, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        <FeatureCard feature={feature} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
