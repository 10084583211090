import { paperStyle } from '../../../../utils/constants'
import AddToBasketButton from './AddToBasketButton'
import { Box } from '@mui/material'
import Specs from './Specs'
import Title from './Title'
import Price from './Price'

const ProductInfo = ({ product }) => {
    const { id, name, price, barcode } = product
    return (
        <Box sx={{ width: '46%', ...paperStyle, p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Title name={name} id={id} barcode={barcode} />
            <Price price={price} sale_price={product?.sale_price} />
            <Specs product={product} />
            <AddToBasketButton product_id={id} />
        </Box>
    )
}

export default ProductInfo