import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export default function SelectBranch({ selectedBranch, handleSelectBranch, branches }) {
  return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="select-branch-label">Салбар: </InputLabel>
            <Select
                labelId="select-branch-label"
                id="select-branch"
                value={selectedBranch?.id}
                label="Салбар"
                onChange={(e) => handleSelectBranch(e.target.value) }
            >
                {
                    branches?.map(branch => (
                        <MenuItem key={branch.id} value={branch.id}>{branch.name||'-'}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
  )
}
