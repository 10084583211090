import ProductListItem from "./ProductListItem"
import { Box } from "@mui/material"

export default function ProductList({ products }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {
                products?.map(product => (
                    <ProductListItem key={product.id} row={product} />
                ))
            }
        </Box>
    )
}
