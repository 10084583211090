import { createTheme } from "@mui/material"

const pharmoTheme = createTheme({
    palette: {
        primary: {
            main: '#238878',
        },
        secondary: {
            main: '#902201',
        },
        trietary: {
            main: '#4CA9EE',
        }
    },
    typography: {
        fontFamily: [
          'Arimo',
          'Rubik',
          'Noto Sans',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
    },
})

const crimson = createTheme({
    palette: {
        primary: {
            main: '#1B2E3C', //#203646
        },
        secondary: {
            main: '#660000',
        },
        neutral: {
            main: '#e0e0e0',
        }
    },
    typography: {
        tableCell: {
            fontSize: 13, // Adjust font size as desired (in rem or px)
        },
        fontFamily: [
            'Lato-Regular',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})

const organic = createTheme({
    palette: {
        primary: {
            main: '#638A55',
        },
        secondary: {
            main: '#C48D60',
        },
        neutral: {
            main: '#97E5DB',
        }
    }
})

export const themes = [
    {
      'name': 'Crimson',
      'theme': crimson
    },
    {
      'name': 'Organic',
      'theme': organic
    },
    {
      'name': 'PharmoTheme',
      'theme': pharmoTheme
    }
  ]
