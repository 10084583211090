import { useLocation, useNavigate, useParams } from 'react-router'
import { Box, Container, Link, Toolbar, Typography } from '@mui/material'
import { getProductById } from '../../../api/products'
import { useEffect, useState } from 'react'
import ProductImage from './ProductImage'
import { getImageUrl } from '../../utils'
import ProductInfo from './ProductInfo'

// Үндсэн компонент
export default function ProductDetail () {
    const location = useLocation()
    const navi = useNavigate()
    const goBack = () => { navi('/') }

    const { productId } = useParams()
    const [product, setProduct] = useState(null)
    const getProd = () => {
        if (productId) {
            getProductById(productId)
                .then(res => {
                    setProduct(res?.data)
                    //console.log("R: ", res?.data)
                })
                .catch(e => { console.log("ERR: ", e) })
        }
    }
    useEffect(() => { getProd(); window.scrollTo(0, 0) }, [])
    useEffect(() => { if(location.pathname) { getProd() } }, [location.pathname])

    const imgs = product?.['images'] ? product['images'].map(img => img['url'])?.map(img => getImageUrl(img, 1000)) : []

    return (
        <>
            {
                product
                    ?   <Container maxWidth='lg' sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Toolbar />
                            <Box sx={{ pl: 1, mt: 1 }}>
                                <Link href='#' onClick={ goBack }>
                                    <Typography fontSize='16px' fontWeight='bold'>Буцах</Typography>
                                </Link>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                <ProductImage imgs={imgs} />
                                <ProductInfo product={product} />
                            </Box>
                        </Container>
                    :   <Container maxWidth='lg' sx={{ height: '100vh' }} />
            }
        </>
    )
}