import { useNavigate } from 'react-router'
import Lobster from '../../../utils/fonts/Lobster.ttf'
import { Box, Typography } from '@mui/material'

export default function PharmoLogo() {
    const fontFace = `
        @fontFace {
        fontFamily: 'Lobster';
        src: url(${Lobster}) format('truetype');
    }`

    const navi = useNavigate()
    const goHome = () => { navi('/home') }

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'end', 
                gap: 1, 
                backgroundColor: 'primary.light', 
                width: 'fit-content', 
                p: '2px',
                '&:hover': { cursor: 'pointer' }
            }}
            onClick={ goHome }
        >
            <img 
                src='/Pharmo_white.png' 
                alt='phamro_logo' 
                style={{ 
                    width: '30px', 
                    height: '30px',
                    borderRadius: '10%',
                }} 
            />
            <Typography
                fontSize='20px' 
                fontWeight='bold'
                fontStyle='italic' 
                textAlign='center'
                sx={{ 
                    fontFamily: 'Lobster', 
                    '@global': { '@fontFace': fontFace }, 
                    pt: 0, 
                    pb: '2px', 
                    lineHeight: '1.2em',
                    color: 'white'
                }}
            >Pharmo</Typography>
        </Box>
    )
}
