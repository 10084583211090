import { Box, Table, TableCell, TableRow, useTheme } from '@mui/material'
import { toPrice } from '../../../../../../../../utils/functions'

export default function OC({ body }) {
    const theme = useTheme()
    const [ isSeller, name ] = body?.data?.who ? body.data.who.split(' ') : []
    const [ totalPrice, payType ] = body?.data?.total ? body.data.total.split(' ') : []
    const pt = payType ? payType === 'С' ? 'Бэлнээр' : 'Зээлээр' : ''

    return (
        <Box>
            <Table sx={{ '& > tr > td': { color: theme.palette.primary.light, p: 0, pl: 1, border: 'none' } }}>
                <TableRow sx={{ p: 1 }}>
                    <TableCell sx={{ width: '150px' }}>{ isSeller === 'seller' ? 'Борлуулагч' : 'Харилцагч' }</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#843333 !important' }}>{name||''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Захиалгын дүн:</TableCell>
                    <TableCell>{totalPrice ? toPrice(totalPrice) : ''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Төлбөрийн хэлбэр:</TableCell>
                    <TableCell>{pt||''}</TableCell>
                </TableRow>
            </Table>
        </Box>
    )
}
