import SliderBox from "./SliderBox"
import { Box } from "@mui/material"

export default function SignInUpBox({ children }) {
    return (
        <div
            style={{
                backgroundColor: '#f1f1f1',
                height: '100vh',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {/* <Navbar /> */}
            <Box
                sx={{ 
                    height: '95%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    px: 2,
                }}
            >
                {children}
                <SliderBox />
            </Box>
        </div>
    )
}
