import { Box } from '@mui/material'
import React from 'react'

export default function Hero() {
    return (
        <Box 
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                background: `linear-gradient(90deg, rgba(35, 136, 120, 1) 30%, rgba(94, 205, 129, 1) 100%)`,
                color: 'white',
                fontSize: '35px',
                borderRadius: 3,
                textAlign: 'center',
                boxShadow: 3,
                p: 2,
                mt: 5,
            }}
        >
            Эмийн бөөний худалдаа, борлуулалт, түгээлтийн <br/> нэгдсэн систмемд тавтай морил!
        </Box>
    )
}
