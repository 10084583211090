import { getToast } from '../../auth/helpers/ToastAlert'
import { Box, Button, Dialog } from '@mui/material'
import { CustomDivider, useOrderCtx } from '..'
import { useState, useEffect } from 'react'
import { ci } from '../../../api/company'
import VerifyBox from './VerifyBox'
import { values } from 'underscore'
import Note from './Note'

export default function Verify({ adrErr, qtysErr, itemsErr, payType, ocp, branchId }) {
    // order ctx
    const { note, setNote } = useOrderCtx()
    // pin code verification
    const [ pinBox, setPinBox ] = useState(false)
    const boxOff = () => { setPinBox(false) }
    const boxOn = () => { setPinBox(true) }

    // err
    const [err, setErr] = useState(null)

    const handleVerifyBoxOpen = () => { boxOn(); setErr(null) }

    // inv
    const [inv, setInv] = useState(null)
    //console.log("INV: ", inv)
    const [initQr, setInitQr] = useState(false)
    useEffect(() => {
        if (inv?.qrTxt && !initQr) { handleVerifyBoxOpen() }
    }, [inv, initQr])

    const createInv = () => {
        setInitQr(true)
        ci({ branchId, note })
            .then(res => {
                const r = res?.data
                if (r) { setInv(res.data) }
            })
            .catch(e => { setErr('Түр хүлээгээд дахин оролдоно уу!'); console.log("INV ERR: ", e) })
            .finally(() => { setInitQr(false) })
    }

    const handleClick = () => {
        if (values(qtysErr)?.length > 0) { setErr(values(qtysErr)[0]); }
        else if (itemsErr) { setErr(itemsErr); }
        else if (adrErr) { setErr('Хүргэлтийн хаяг сонгоно уу!'); }
        else if (payType === null) { setErr('Төлбөрийн хэлбэр сонгоно уу!'); }
        else {
            if (payType === 'C') {
                const tp = window.localStorage.getItem('tp')
                if (tp && parseFloat(tp) < 10) { setErr('Захиалгын үнийн дүн хэт бага байна!') }
                else {
                    // create order and create qpay invoice
                    createInv()
                }
            }
            else if (payType === 'L') { handleVerifyBoxOpen() }
        }
    }

    return (
        <>
            <CustomDivider text={'Баталгаажуулах'} />
            <Note note={note} setNote={setNote} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 8 }}>
                <Button 
                    size='small' variant='contained' sx={{ width: '200px', mt: 3 }}
                    onClick={ handleClick } disabled={ocp||initQr}
                >Баталгаажуулах</Button>
                <Dialog open={pinBox} onClose={boxOff}>
                    <VerifyBox 
                        boxOff={boxOff} 
                        payType={payType}
                        inv={inv}
                    />
                </Dialog>
                {getToast(Boolean(err), () => { setErr(null) }, err, 'warning', 2000)}
            </Box>
        </>
    )
}
