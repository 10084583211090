import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";

export default function Navbar() {
    return (
        <AppBar variant="dense">
            <Toolbar>
                <Container
                    maxWidth='xl' 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                        <img 
                            src='/Pharmo_white.png' 
                            alt='phamro_logo' 
                            style={{ 
                                width: '30px', 
                                height: '30px',
                                borderRadius: '10%',
                            }} 
                        />
                        <Typography variant='h6' sx={{ color: 'white' }}>Pharmo</Typography>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    )
}
