import { Box, Typography, useTheme } from '@mui/material'
import { getLocalNotifs } from '../..'
import getNotifBody from './Body'

export default function Item({ notif, updateNotifs, setCnt }) {
    const theme = useTheme()
    const handleUpdate = () => {
        const prev = getLocalNotifs()
        if (!prev?.[notif.id]) {
            updateNotifs({[notif.id]: true})
            setCnt(cnt => cnt - 1)
        }
    }

    return (
        <Box 
            onMouseEnter={handleUpdate}
            sx={{ 
                backgroundColor: '#f1f1f1', p: '4px', mt: '10px', 
                border: '1px solid #e1e1e1', mb: 1, textAlign: 'left'
            }}
        >
            <Box 
                sx={{ 
                    display: 'flex', justifyContent: 'space-between', 
                    p: '2px', borderBottom: `1px solid ${theme.palette.primary.light}`, color: theme.palette.primary.light
                }}
            >
                <Typography fontSize='14px' sx={{ color: theme.palette.primary.main }}>{notif?.title}</Typography>
                <Typography fontSize='14px' sx={{ color: theme.palette.primary.main }}>{notif?.date}</Typography>
            </Box>
            <Box sx={{ p: '2px', mt: '4px' }}>
                {getNotifBody(notif?.type, notif?.body)}
            </Box>
        </Box>
    )
}
