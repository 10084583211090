import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { FilterAlt } from "@mui/icons-material"

export default function FilterRow({ filtering, setFiltering }) {
	const theme = useTheme()
	return (
		<TableRow
			sx={{
				"& > td": {
				p: "2px ",
				borderBottom: "1px solid gray",
				borderBottomColor: theme.palette.primary.light,
				},
			}}
		>
			<TableCell align="center" sx={{ p: "2px" }}>
				<Tooltip title="Шүүж хайх мөр" placement="right">
				<FilterAlt sx={{ color: theme.palette.secondary.light, fontSize: "20px", margin: 0 }} />
				</Tooltip>
			</TableCell>
			<TableCell />
			<TableCell />
			<TableCell />
			<TableCell />
			<TableCell />
			<TableCell />
			<TableCell width={80} />
		</TableRow>
	)
}
