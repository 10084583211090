import { Box, Divider } from '@mui/material'
import Notification from './Notification'
import Basket from './Basket'
import User from './User'
//import BasketTemplet from './BasketTemplet'

export default function RightSection() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Basket />
            {/* <BasketTemplet /> */}
            <Divider sx={{ mx: 1 }} orientation='vertical' flexItem />
            <Notification />
            <Divider sx={{ mx: 1 }} orientation='vertical' flexItem />
            <User />
        </Box>
    )
}
