import { useEffect, useLayoutEffect, useRef } from "react"
import { Paper } from "@mui/material"

export default function ListComboBox({ props, hasNextPage, setPage }) {
    const paperRef = useRef(null)
    const handleScroll = () => {
        const element = paperRef.current
        if (element) {
            const { scrollTop, scrollHeight, clientHeight } = element
            window.localStorage.setItem('product_search_scrollTop', scrollTop)
            if (scrollTop + clientHeight >= scrollHeight) {
                if (hasNextPage) {
                    setPage(prev => prev + 1)
                }
            }
        }
    }

    useLayoutEffect(() => {
        const element = paperRef.current
        if (element) {
            const st = window.localStorage.getItem('product_search_scrollTop')
            if (st) { element.scrollTop = st }
        }
    }, [])

    useEffect(() => {
        const element = paperRef.current
        if (element) { 
            element.addEventListener('scroll', handleScroll)
        }
        return () => { if (element) { element.removeEventListener('scroll', handleScroll) } }
    }, [])

    return (
        <Paper
            ref={paperRef}
            {...props}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 600,
                boxShadow: 10,
                overflowY: 'auto',
                position: 'relative',
            }}
        >
            {props.children}
        </Paper>
    )
}