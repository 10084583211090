import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { ISCOMPANIESCUST } from "../../../../../../utils/constants"
import TextFieldFilter from './FilterCells/TextFieldFilter'
import { FilterAlt } from "@mui/icons-material"
import DropCell from '../DropCell'

export default function FilterRow({ filtering, setFiltering }) {
	const theme = useTheme()
	return (
		<TableRow
			sx={{
				"& > td": {
					p: "2px ",
					borderBottom: "1px solid gray",
					borderBottomColor: theme.palette.primary.light,
				},
			}}
		>
			<TableCell align="center" sx={{ p: "2px" }}>
				<Tooltip title="Шүүж хайх мөр" placement="right">
					<FilterAlt sx={{ color: theme.palette.secondary.light, fontSize: "20px", margin: 0 }} />
				</Tooltip>
			</TableCell>
			<TextFieldFilter setFilters={setFiltering} field="name" />
			<TextFieldFilter setFilters={setFiltering} field="rn" />
			<TextFieldFilter setFilters={setFiltering} field="email" />
			<TextFieldFilter setFilters={setFiltering} field="phone" />
			<DropCell field='is_cmp' choices={ISCOMPANIESCUST} filters={filtering} setFilters={setFiltering} />
			<TableCell />
			<TableCell />
			<TableCell />
		</TableRow>
	)
}
