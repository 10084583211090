import { useAuthCtx } from "../../../../context/AuthCtx"
import { Box } from "@mui/material"
import DownloadAppButtons from ".."
import Navbar from "./Navbar"

export default function DownloadAppsPage() {
    const { isMobile } = useAuthCtx()
    return (
        <Box>
            <Navbar />
            <DownloadAppButtons isMobile={isMobile} />
        </Box>
    )
}