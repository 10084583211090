import AxiosWithToken from "../global/axiosBase"
import { getPageParams } from "./utils"

const getQueryParams = (filters, page, pageSize, qParam) => {
    for (const f of filters) {
        qParam += qParam.endsWith('/') ? '?' : '&'
        qParam += `${f.k}${f.o}${f.v}`
    }
    qParam = getPageParams(page, pageSize, qParam)
    return qParam
}
export const getProducts = ({ queryKey }) => {
    const [ _, filters, page, pageSize ] = queryKey
    let qParam = getQueryParams(filters, page, pageSize, 'product/')
    return AxiosWithToken.get(qParam)
}

export const getProductsByIds = (pIds) => {
    return AxiosWithToken.get('product/', { params: { pIds } })
}

export const getProduct = (productId) => {
    return AxiosWithToken.get(`product/${productId}/`)
}

export const addNewProduct = (productInfo) => {
    return AxiosWithToken.post('product/', productInfo)
}

export const updateProduct = (productId, productInfo) => {
    return AxiosWithToken.patch(`/product/${productId}/`, productInfo)
}

export const deleteProduct = (pId) => {
    return AxiosWithToken.delete(`product/bulk_delete/${pId}/`)
}

export const getSearchResults = ({ queryKey }) => {
    const [ _, searchKey, value, page, pageSize ] = queryKey
    return AxiosWithToken.get(`products/search/?k=${searchKey}&v=${value}&page=${page}&page_size=${pageSize}`)
}

export const getProductAddCols = () => {
    return AxiosWithToken.get('prod_cols/')
}

export const getProductFieldsInfo = () => {
    return AxiosWithToken.get('product_fields_info/')
}
export const updateProductFieldsInfo = (data) => {
    return AxiosWithToken.patch('product_fields_info/', data)
}

export const deleteProds = (pIds) => {
    return AxiosWithToken.post('product_bulk_delete/', { pIds })
}

export const getMnfrs = () => {
    return AxiosWithToken.get('product/mnfrs/')
}

export const getVndrs = () => {
    return AxiosWithToken.get('product/vndrs/')
}

export const checkProductQty = (data) => {
    return AxiosWithToken.patch('check_qty/', {data : data})
}

export const createBulkXlsx = (data) => {
    return AxiosWithToken.post('product_bulk_xlsx/', data)
}

export const checkDrugToBind = (upc) => {
    return AxiosWithToken.get(`drug/search/?upc=${upc}&check=1`)
}

export const getDrugToBind = (upc) => {
    return AxiosWithToken.get(`drug/search/?upc=${upc}`)
}
export const getImgsToBind = (upc) => {
    return AxiosWithToken.get(`product/default_image/?upc=${upc}`)
}