import { Dialog, InputAdornment, TableCell, TextField, useTheme } from '@mui/material'
import { useProductListCtx } from '../../../..'
import { Close } from '@mui/icons-material'
import MnfrList from './MnfrList'
import { useState } from 'react'

export default function ByMnfr({ selMnfrName }) {
    const theme = useTheme()
    const { setFilters, mnfrs } = useProductListCtx()
    const [open, setOpen] = useState(false)
    const toOpen = () => { setOpen(true) }
    const toClose = () => { setOpen(false) }

    const handleSelect = (m) => {
        setFilters(prev => ([
            ...prev.filter(f => f.k !== 'mnfr'),
            { k: 'mnfr', v: JSON.stringify([m.id]), o: '=' }
        ]))
        toClose()
    }
    const handleClear = (e) => {
        setFilters(prev => ([ ...prev.filter(f => f.k !== 'mnfr')]))
        toClose()
        e.stopPropagation()
    }

    if (mnfrs?.length === 0) return <TableCell key='mnfr' />

    return (
        <TableCell key='mnfr' sx={{ p: 0, px: '2px' }}>
            <TextField
                onClick={toOpen}
                size='small'
                placeholder='Үйлдвэрлэгчээр'
                value={selMnfrName||''}
                sx={{
                    flex: 1, width: '100%',
                    '& fieldset': { borderColor: theme.palette.primary.light, borderRadius: 0, borderWidth: selMnfrName ? 3 : 1 },
                    '&:hover fieldset': { borderColor: theme.palette.primary.light },
                    '& .MuiInputBase-input::placeholder': { color: theme.palette.primary.light, fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                inputProps={{ style: { padding: '3px 5px', fontSize: '14px', color: theme.palette.primary.light, cursor: 'pointer' } }}
                InputProps={{ 
                    readOnly: true,
                    endAdornment: (<InputAdornment>
                                    <Close
                                        sx={{ 
                                            fontSize: '18px', color: theme.palette.primary.light,
                                            '&:hover': { cursor: 'pointer' },
                                            visibility: Boolean(selMnfrName) ? 'visible' : 'hidden'
                                        }} 
                                        onClick={handleClear}
                                    />
                                </InputAdornment>)
                }}
                autoComplete='off'
            />
            <Dialog open={open} onClose={toClose}>
                <MnfrList handleSelect={handleSelect} toClose={toClose} />
            </Dialog>
        </TableCell>
    )
}
