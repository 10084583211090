import { getToast } from '../../../helpers/ToastAlert'
import { Box, Dialog } from '@mui/material'
import OTPVerify from './OTPVerify'
import { useEffect } from 'react'
import { useState } from 'react'

export default function SignUpVerify({ values, setErrors, open, handleClose, goToSignIn }) {
    const [alert, setAlert] = useState(null)
    const alertOff = () => { setAlert(null) }

    useEffect(() => { setAlert({ m: 'Имэйл хаяг руу баталгаажуулах код илгээлээ!', s: 'success' }) }, [])

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
        >
            <Box sx={{ p: 2, minWidth: '300px', maxWidth: '300px', }}>
                <OTPVerify values={values} setErrors={setErrors} goToSignIn={goToSignIn} />
            </Box>
            { getToast(Boolean(alert), alertOff, alert?.m, alert?.s, 2000 ) }
        </Dialog>
    )
}
