import { Box, Tab, Tabs } from "@mui/material"
import SalesReport from "./SalesReport"
import { useState } from "react"

export default function Report() {
    const [tab, setTab] = useState(0)

    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                aria-label='tabs'
                sx={{
                    minHeight: '30px', height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'Борлуулалтын тайлан'} value={0} />
            </Tabs>
            <Box sx={{ height: '100%' }}>
                {tab === 0 && <SalesReport />}
            </Box>
        </>
    )
}

