import { createContext, useContext, useState } from 'react'
import { getToast } from '../auth/helpers/ToastAlert'
import { Outlet } from 'react-router'
import Navbar from '../navbar'
import Footer from './footer'

const HomeCtx = createContext()
export const useHomeCtx = () => useContext(HomeCtx)

export default function Home() {
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }
    const alertWarning = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }
    
    return (
        <HomeCtx.Provider 
            value={{
                msg, setMsg, alertWarning,
            }}
        >
            <div 
                style={{
                    backgroundColor: '#f1f1f1', 
                    width: '100%', 
                    margin: 0, 
                    padding: 0, 
                    minHeight: '100vh', 
                    position: 'relative'
                }}
            >
                {getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
                <Navbar />
                <Outlet />
                <Footer />
            </div>
        </HomeCtx.Provider>
    )
}
