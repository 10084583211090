import { InputAdornment, TableCell, TextField, useTheme } from '@mui/material'
import { useProductListCtx } from '../../..'
import { Close } from '@mui/icons-material'
import { useState, useEffect } from 'react'

export default function ByBarcode() {
    const theme = useTheme()
    const { filters, setFilters } = useProductListCtx()
    const prevVal = filters?.find(f => f?.k === 'barcode')
    const [value, setValue] = useState(prevVal?.v||null)
    const [error, setError] = useState(null)
    useEffect(() => { if (error) { setTimeout(() => { setError(null) }, 2000) } }, [error])

    const doFilter = () => {
        if (value) {
            if (!isNaN(value)) {
                setFilters(prev => {
                    const f = { k: 'barcode', v: value, o: '=' }
                    if (prev?.length) {
                        if (prev?.map(p => p?.k)?.includes('barcode')) {
                            return [ ...prev.map(p => p.k === 'barcode' ? f : p)]
                        } else { return [ ...prev, f ] }
                    } else {
                        return [f] 
                    }
                })
                setError(null)
        } else { setError('Баркод буруу!') } }
    }    

    const doClear = () => {
        setFilters(prev => prev?.filter(p => p.k !== 'barcode'))
        setValue(null)
    }
    const handleEnter = (e) => { if (e?.code === 'Enter') { doFilter() } }

    return (
        <TableCell key='barcode' sx={{ p: 0, px: '2px' }}>
            <TextField
                size='small'
                placeholder='Баркод'
                value={value||prevVal?.v||''}
                onChange={e => { setValue(e?.target?.value) }}
                sx={{
                    flex: 1, width: '100%',
                    '& fieldset': { borderColor: theme.palette.primary.light, borderRadius: 0, borderWidth: prevVal ? 3 : 1 },
                    '&:hover fieldset': { borderColor: theme.palette.primary.light },
                    '& .MuiInputBase-input::placeholder': { color: theme.palette.primary.light, fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                InputProps={{
                    endAdornment: (<InputAdornment>
                                        <Close
                                            sx={{fontSize: '18px', color: theme.palette.primary.light, 
                                                '&:hover': { cursor: 'pointer' },
                                                visibility: value ? 'visible' : 'hidden'}}
                                            onClick={doClear}
                                        />
                                    </InputAdornment>)
                }}
                inputProps={{ style: { padding: '3px 5px', fontSize: '14px', color: theme.palette.primary.light } }}
                onKeyDown={ handleEnter }
                error={Boolean(error)}
                helperText={Boolean(error) ?  error :  ''}
                autoComplete='off'
            />
        </TableCell>
    )
}
