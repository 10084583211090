import { toPrice } from '../../../../../../../../../utils/functions'
import { TableCell, TableRow } from '@mui/material'

export default function ProductItem({ product, setSelectedProduct }) {
    const handleSelect = (product) => { setSelectedProduct(product) }

    return (
        <TableRow
            sx={{ '& > td': { py: '1px', px: '10px' }, '&:hover': { backgroundColor: '#e5e5e5' }, cursor: 'pointer'}}
            onClick={() => { handleSelect(product) }}
        >
            <TableCell sx={{ width: '200px', textAlign: 'left', paddingLeft: '5px' }}>{product.name||'-'}</TableCell>
            <TableCell sx={{ width: '80px' }}>{product.barcode||'-'}</TableCell>
            <TableCell sx={{ width: '100px', textAlign: 'right', paddingRight: '5px' }}>{product.price ? toPrice(product.price) : '-'}</TableCell>
        </TableRow>
    )
}
