import SimpleImageSlider from 'react-simple-image-slider'
import { Toolbar } from '@mui/material'

export const images = [
    {
        url: '/images/img1.jpg',
        link: '/products/1'
    },
    {
        url: '/images/img2.jpg',
        link: '/products/2'
    },
    {
        url: '/images/img3.jpg',
        link: '/products/3'
    }
]

export default function ImageSlider ({ images = [] }) {
    const handleClick = (idx, e) => {
        window.location.href = Object.keys(images[idx]).includes('link') ? images[idx]['link'] : 'home'
    }

    return (
        <div style={{ width: '80%', marginTop: '8px' }}>
            <Toolbar />
            <SimpleImageSlider
                images={images}
                width='80%'
                height='500px'
                showBullets={true}
                showNavs={true}
                autoPlay={true}
                onClick={handleClick}
                style={{ width: '100%', maxWidth: '100%', height: '500px', maxHeight: '500px', overflow: 'hidden' }}
            />
        </div>
    )
}

