import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Clear } from '@mui/icons-material'
import Suppliers from './Suppliers'

const labels = { name: 'нэрээр', barcode: 'баркодоор'}
export default function SearchInput({ params, value, setSearch, setValue, searchKey, disabled }) {
    const handleClear = (e) => {
        setValue('')
        setSearch(null)
        e.stopPropagation()
    }
    //console.log("PARAMS: ", value)

    return (
            <TextField
                {...params}
                inputProps={{ ...params.inputProps, style: { padding: 0, fontSize: '14px', border: 'none' } }}
                size='small'
                placeholder={`Барааны ${labels[searchKey]} хайх`}
                variant='outlined'
                sx={{ backgroundColor: 'white', borderColor: 'transparent' }}
                value={value||''}
                onChange={e => setSearch(e.target.value)}
                InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' },
                    startAdornment: <InputAdornment position="start">
                                        <Suppliers disabled={disabled} />
                                    </InputAdornment>,
                    endAdornment: null,
                }}
                disabled={disabled}
            />
    )
}
