import { toPrice } from '../../../../../utils/functions'
import { Box, Paper, Typography } from '@mui/material'
import AddToBasketWithQty from './AddToBasketWithQty'
import CardMedia from '@mui/material/CardMedia'
import { getImageUrl } from '../../../../utils'
import { useNavigate } from 'react-router'
import AddToBasket from './AddToBasket'
import { useState } from 'react'

export const btnStyle = {
    '&:hover' : { cursor: 'pointer', backgroundColor: 'primary.main' },
    backgroundColor: 'primary.main',
    transition: 'all 0.2s linear',
    boxShadow: '3px 3px #e0e0e0',
    position: 'absolute',
    fontSize: '13px',
    color: 'white',
    p: '4px 6px',
    mt: '2px'
}

export default function Product ({ product }) {
    const { id, image, price, name, discount } = product
    const imgUrl = getImageUrl(image, 150) || null
    const navigate = useNavigate()
    const goToDetail = () => { navigate(`/products/${product.id}`) }

    const [isHover, setIsHover] = useState(false)

    const price2 = discount > 0 ? price - (price * discount / 100) : null

    return (
        <Box
            sx={{
                p: 0, 
                m: 0, 
                border: '1px solid',
                height: { md: '210px', sm: '200px', xs: '190px' },
                width: { md: '180px', sm: '160px', xs: '150px' },
                borderRadius: 0, 
                background: '#f7f7f5', 
                position: 'relative',
                borderColor: isHover ? 'secondary.main' : 'primary.main'
            }}
            component={Paper}
            elevation={ isHover ? 6 : 2 }
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {
                discount > 0 &&
                <Box 
                    sx={{ 
                        position: 'absolute', 
                        top: -10, 
                        right: -10, 
                        p: '1px 4px',
                        zIndex: 1000,
                        boxShadow: 3,
                        fontSize: '13px',
                        bgcolor: 'secondary.light',
                        color: 'white',
                        borderRadius: 1,
                        borderColor: 'white',
                        fontWeight: 600
                    }}
                >{`-${discount} %`}</Box>
            }
            <CardMedia
                onClick={goToDetail}
                component="img"
                image={imgUrl || require(`./noimage.jpg`)}
                alt="random"
                sx={{
                    mb: 1, 
                    height: '60%', 
                    minWidth: '180px',
                    borderRadius: 'inherit', 
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0, 
                    opacity: '80%',
                    '&:hover': { cursor: 'pointer', opacity: '100%' }
                }}
            />
            <Typography 
                gutterBottom 
                fontWeight='bold'
                noWrap
                sx={{
                    padding: '1px 3px', 
                    ml: 1, 
                    mt: 1, 
                    mb: 0, 
                    p: 0,
                    fontSize: { md: '13px', sm: '12px', xs: '11px' },
                    '&:hover': { cursor: 'pointer' }
                }}
                onClick={ goToDetail }
            >{name}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {price2 && <Typography
                    noWrap
                    sx={{
                        fontSize: { md: '13.5px', sm: '12px', xs: '11px' },
                        fontWeight: 700, 
                        color: 'secondary.light', 
                        transition: 'all 0.2s linear',
                        width: 'fit-content', 
                        mt: isHover ? 0 : 1, 
                        textAlign: 'center', 
                        mr: '5px'
                    }}
                >
                    {toPrice(price2)}
                </Typography>}
                <Typography
                    noWrap
                    sx={{
                        fontSize: { md: '13.5px', sm: '12px', xs: '11px' },
                        fontWeight: price2 ? 600 : 'bolder', 
                        color: price2 ? 'grey' : 'secondary.light', 
                        transition: 'all 0.2s linear',
                        width: 'fit-content', 
                        mt: isHover ? 0 : 1, 
                        textAlign: 'center',
                        textDecoration: price2 ? 'line-through' : 'none'
                    }}
                >
                    {price2 ? '(' : ''}{toPrice(price)}{price2 ? ')' : ''}
                </Typography>
            </Box>
            <AddToBasket product={product} isHover={isHover} />
            <AddToBasketWithQty product_id={id} isHover={isHover} setIsHover={setIsHover} />
        </Box>
    )
}