import { getSearchResults } from '../../../api/product'
import { isTokenValid } from '../../../utils/functions'
import { scrollStyle } from '../../../utils/constants'
import { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box } from '@mui/material'
import ListComboBox from './ListComboBox'
import SearchField from './SearchField'
import SearchInput from './SearchInput'
import { useQuery } from 'react-query'
import ProductBox from './ProductBox'

export const rowHeight = '80px'

export default function SimpleSearch() {
    const searchWidth = '600px'
    const { email } = isTokenValid()
    const [value, setValue] = useState('')
    const [searchKey, setSearchKey] = useState('name')

    const [page, setPage] = useState(1)

    useEffect(() => {
        setPage(1)
    }, [value, searchKey])
    const { data, error } = useQuery(['get_search', searchKey, value, page, 10], getSearchResults, { enabled: Boolean(email && value) })
    const [results, setResults] = useState([])
    const allCount = data?.data?.count||0
    const currentCount = results?.length||0
    const hasNextPage = allCount > currentCount
    useEffect(() => {
        if (data?.data?.results?.length) {
            const newData = data.data.results
            if (page > 1) { setResults([...results, ...newData]) }
            else { setResults(data.data.results) }
        }
    }, [data])

    useEffect(() => { if (error?.response?.status === 400 && 'barcode' in error.response.data) { setResults([]) } }, [error])

    // delayed search
    const [search, setSearch] = useState(null)
    //console.log("SEARCH: ", search, value)
    const searchTimeoutRef = useRef(null)
    useEffect(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
            searchTimeoutRef.current = null
        }
        searchTimeoutRef.current = setTimeout(() => { setValue(search) }, 650)
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [search])

    const [showResults, setShowResults] = useState(false)

    const disabled = window.location.pathname === '/promo_order'

    const inputRef = useRef(null)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleSelectProduct = (productId) => {
        const inputElement = inputRef.current?.querySelector('input')
        if (inputElement) { inputElement.blur() }
        setSelectedProduct(productId)
    }

    return (
        <Box sx={{ mt: 1 }}>
            <Autocomplete
                id="company-select"
                ref={inputRef}
                sx={{
                    width: searchWidth,
                    '& .MuiAutocomplete-option:hover': { backgroundColor: 'transparent' },
                }}
                noOptionsText={'Тохирох бараа олдсонгүй!'}
                disabled={disabled}
                open={Boolean(value && showResults)}
                options={results}
                getOptionLabel={(option) => option?.[searchKey]}
                clearOnBlur={false}
                onFocus={() => { setShowResults(true) }}
                onBlur={() => { setShowResults(false) }}
                renderOption={(props, option) => (
                    <ProductBox 
                        {...props} 
                        sx={{ p: 0 }} 
                        row={option} 
                        selectedProduct={selectedProduct} 
                        handleSelectProduct={handleSelectProduct}
                    />
                )}
                renderInput={(params) => (
                    <SearchInput
                        params={params}
                        value={value}
                        setValue={setValue}
                        setSearch={setSearch}
                        searchKey={searchKey}
                        disabled={disabled}
                    />
                )}
                ListboxComponent={
                    (props) => <ListComboBox
                        props={props} 
                        allCount={allCount}
                        hasNextPage={hasNextPage} 
                        setPage={setPage} 
                        currentCount={currentCount}
                    />
                }
                ListboxProps={{
                    sx: {
                        width: searchWidth,
                        maxHeight: 490,
                        boxShadow: 50,
                        border: 1,
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 9, height: 9 },
                    }
                }}
            />
            <SearchField
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                searchWidth={searchWidth}
                disabled={disabled}
            />
        </Box>
    )
}