import { TextField } from '@mui/material'

const labels = { name: 'нэрээр', barcode: 'баркодоор'}
export default function SearchInput({ params, search, setSearch, searchKey }) {
    const handleClear = () => {
        setSearch(null)
    }

    return (
            <TextField
                {...params}
                inputProps={{ ...params.inputProps, style: { padding: '4px', fontSize: '14px' } }}
                size='small'
                placeholder={`Барааны ${labels[searchKey]} хайх`}
                variant='outlined'
                sx={{ backgroundColor: 'white' }}
                value={search}
                onChange={e => setSearch(e.target.value)}
                InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' },
                    endAdornment: null,
                }}
            />
    )
}
