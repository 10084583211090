import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'

export default function PaginationControls({ handlePage, handleFirst, handleLast, page, lastPage}) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <Tooltip title='Эхний хуудас' placement='bottom'>
                <IconButton disabled={page === 1} onClick={handleFirst}>
                    <FirstPage sx={{ fontSize: '20px' }} />
                </IconButton>
            </Tooltip>
            <Tooltip title='Өмнөх хуудас' placement='bottom'>
                <IconButton disabled={page === 1} onClick={() => handlePage(false)}>
                    <NavigateBefore sx={{ fontSize: '20px' }} />
                </IconButton>
            </Tooltip>
            <Tooltip title='Одоогийн хуудас' placement='bottom'>
                <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: 'primary.main' }}>
                    {page}
                </Typography>
            </Tooltip>
            <Tooltip title='Дараагийн хуудас' placement='bottom'>
                <IconButton disabled={page === lastPage} onClick={() => handlePage(true)}>
                    <NavigateNext sx={{ fontSize: '20px' }} />
                </IconButton>
            </Tooltip>
            <Tooltip title='Сүүлийн хуудас' placement='bottom'>
                <IconButton disabled={page === lastPage} onClick={handleLast}>
                    <LastPage sx={{ fontSize: '20px' }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
