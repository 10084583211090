import { Box, Button, InputAdornment, MenuItem, TextField, useTheme } from '@mui/material'
import DropMenu from '../../../../../../../helpers/DropMenu'
import { Close } from '@mui/icons-material'
import { useEffect, useState } from 'react'

const ops = [
    { label: '=', value: '=' },
    { label: '<', value: '__lt=' },
    { label: '>', value: '__gt=' },
]
export default function FieldInput({ filters, setFilters, toClose, field }) {
    const [val, setVal] = useState(filters?.find(f => f.k === field)?.v || null)
    const theme = useTheme()
    const pl = theme.palette.primary.main
    const isDate = ['created'].includes(field)
    const handleChange = (e) => {
        if (isDate) {
            let v = e.target.value
            const vl = v?.length
            if (vl <= 10) {
                if (vl === 4) { v = v + '-' }
                else if (vl === 7) { v = v + '-' }
                setVal(v)
            }
        } else {
            setVal(e.target.value)
        }
    }
    const [op, setOp] = useState(ops[0])
    const handleFilter = () => {
        if (val) {
            if (filters == null || filters === undefined) {
                setFilters([{ k: field, o: `${op.value}`, v: val }]);
            } else {
                setFilters([...filters?.filter(f => f.k !== field), { k: field, o: `${op.value}`, v: val }]);
            }
            toClose();
        }
    }

    useEffect(() => { document.getElementById(field)?.focus() }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2, height: '130px', alignItems: 'top' }}>
            <DropMenu
                title={<MenuItem dense sx={{ border: 1 }}>{op.label}</MenuItem>}
                detail={
                    ops.filter(o => o.label !== op.label).map(o => {
                        return <MenuItem dense sx={{ border: 1 }} value={o.value} onClick={() => { setOp(o) }}>{o.label}</MenuItem>
                    })
                }
                left={0}
                top={30}
            />
            <TextField
                id={field}
                value={val || ''}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (<InputAdornment>
                        <Close
                            sx={{ fontSize: '18px', color: pl, '&:hover': { cursor: 'pointer' } }}
                            onClick={() => { setVal(null) }}
                        />
                    </InputAdornment>)
                }}
                inputProps={{ style: { padding: '4.5px', paddingLeft: '10px', cursor: 'pointer' } }}
                sx={{
                    '& fieldset': { borderColor: pl, borderRadius: 0 },
                    '&:hover fieldset': { borderColor: pl },
                    '& .MuiInputBase-input::placeholder': { color: pl, fontSize: '14px', opacity: 1, ml: '5px' },
                    mr: 1, ml: 1, width: '150px'
                }}
                size='small'
            />
            <Button size='small' onClick={handleFilter} variant='contained' sx={{ height: 'fit-content', p: '5.5px' }}>Шүүх</Button>
        </Box>
    )
}
