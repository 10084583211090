import { removeFromBasket, updateBasket } from '../../../../../../api/basket'
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { useAuthCtx } from '../../../../../../context/AuthCtx'
import { toPrice } from '../../../../../../utils/functions'
import { ShoppingCart } from '@mui/icons-material'
import { getImageUrl } from '../../../../../utils'
import { useNavigate } from 'react-router'

function TooltipLabel ({msg}) {
    return <Typography sx={{ fontSize: '13px' }}>{msg}</Typography>
}

const rowHeight = '130px'
export default function ProductListItem({ row }) {
    const theme = useTheme()
    const sl = theme.palette.secondary.light
    const { basket, setBasket, setMsg, toastWait } = useAuthCtx()
    const item = basket?.items?.find(i => i.product_id === row?.id)
    const price2 = (row.discount && row.discount > 0) ? row.price - (row.price * row.discount / 100) : null
    const handleAdd = () => {
        updateBasket({ product_id: row?.id, qty: 1 })
            .then(res => {
                const d = res?.data
                if (d?.available_qty) {
                    setMsg({m: `Боломжит үлдэгдэл: ${d.available_qty}`, s: 'warning'})
                } else if (d?.available_qty === 0) {
                    setMsg({m: 'Бараа дууссан байна!', s: 'warning'})
                }
                // if success
                if (d?.id && d?.totalPrice) {
                    setBasket(d)
                    setMsg({ m: 'Сагсанд амжилттай нэмэгдлээ!', s: 'success' })
                }
            })
            .catch(e => { console.log("ERR: ", e); toastWait() })
    }
    const handleRemove = () => {
        removeFromBasket(item?.id)
            .then(res => {
                const newBask = res?.data
                if (newBask) { setBasket(newBask) } else { setBasket(null) }
            })
            .catch(e => { console.log("ERRs: ", e); toastWait() })
    }

    const handleClick = () => { item ? handleRemove() : handleAdd() }

    const navigate = useNavigate()
    const goToDetail = () => { navigate(`/products/${row?.id}`) }

    return (
        <Box 
            sx={{
                boxShadow: 3,
                mt: '5px',
                mx: 1,
                border: '1px solid', 
                borderColor: 'transparent',
                borderRadius: '5px',
                backgroundColor: 'white',
                '&:hover': { cursor: 'pointer', backgroundColor: '#f2f2f2' },
                transition: 'border 0.15s ease-in-out',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                width: '90%',
            }}
            onClick={ goToDetail }
        >
            <img src={row?.image ? getImageUrl(row.image, 150) : require(`./noimage.jpg`)} style={{ width: rowHeight, height: rowHeight, borderRadius: '5px', border: '1px solid gainsboro' }} />
            <Box sx={{ flexGrow: 1, textAlign: 'left', ml: 1, flexDirection: 'column' }}>
                <Typography fontSize='15px' fontWeight={700} textAlign='left'>{row.name}</Typography>
                {
                    Boolean(row.expDate && row.in_stock) &&
                    <Typography 
                        sx={{ 
                            fontSize: '12.5px',
                            fontWeight: 600,
                            color: 'grey',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        Барааны дуусах хугацаа:&nbsp;<span style={{ color: sl, fontWeight: 700 }}>{row.expDate}</span>
                    </Typography>
                }
                { 
                    !row.in_stock && 
                    <Typography 
                        sx={{ 
                            fontSize: '12.5px',
                            fontWeight: 600,
                            color: 'warning.main',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >Үлдэгдэлгүй</Typography>
                }
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '16%',
                    minWidth: '16%',
                    mr: 5,
                }}
            >
                {
                    Boolean(price2) &&
                    <Typography 
                        sx={{ 
                            color: 'secondary.light', 
                            textAlign: 'right',
                            fontSize: '15px',
                            fontWeight: 700,
                        }}
                    >{toPrice(price2)}</Typography>
                }
                <Typography
                    sx={{ 
                        color: price2 ? 'grey' : 'secondary.light', 
                        mr: 1, 
                        textAlign: 'right',
                        fontSize: '15px',
                        fontWeight: price2 ? 600 : 700,
                        textDecoration: price2 ? 'line-through' : 'none'
                    }}
                >{toPrice(row.price)}</Typography>
            </Box>
            <Tooltip
                title={
                    Boolean(item) 
                        ?   <TooltipLabel msg='Сагснаас хасах' />
                        :   <TooltipLabel msg='Сагсанд нэмэх' />
                } 
                placement='right'
            >
                <IconButton
                    sx={{
                        border: 2,
                        mr: 3,
                        position: 'absolute', 
                        right: 10,
                        p: '5px',
                        backgroundColor: Boolean(item) ? 'secondary.light' : 'primary.light', 
                        '&:hover': { backgroundColor:  Boolean(item) ? 'secondary.light' : 'primary.light' }, 
                        border: '1px solid',
                        borderColor: 'secondary.light',
                        boxShadow: 3,
                        transition: 'visibility 0.3s linear 0'
                    }}
                    onClick={ handleClick }
                >
                    <ShoppingCart sx={{ color: 'white' }} />
                </IconButton>
            </Tooltip>
            {
                Boolean(row.discount && row.discount > 0) &&
                <Box 
                    sx={{ 
                        position: 'absolute', 
                        top: 5, 
                        right: 5, 
                        p: '1px 4px',
                        zIndex: 1000,
                        boxShadow: 3, 
                        fontSize: '14px',
                        bgcolor: 'secondary.light',
                        color: 'white',
                        borderRadius: 1, 
                        borderColor: 'white', 
                        fontWeight: 600
                    }}
                >{`-${row.discount} %`}</Box>
            }
        </Box>
    )
}