import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { ACTION_TYPES } from '../../../Promotion/PromoTable/utils'
import { SUPPLIER_ROLES } from '../../../../../../utils/constants'
import TextFieldFilter from './FilterCells/TextFieldFilter'
import { FilterAlt } from "@mui/icons-material"
import DropCell from '../DropCell'
import ByField from './ByField'

export default function FilterRow({ filtering, setFiltering, staffs }) {
  const theme = useTheme()
  return (
    <TableRow
      sx={{ "& > td": { p: "2px ", borderBottom: "1px solid gray", borderBottomColor: theme.palette.primary.light, } }}
    >
      <TableCell align="center" sx={{ p: "2px" }}>
        <Tooltip title="Шүүж хайх мөр" placement="right">
          <FilterAlt sx={{ color: theme.palette.secondary.light, fontSize: "20px", margin: 0 }} />
        </Tooltip>
      </TableCell>
      <DropCell field='action' choices={ACTION_TYPES} filters={filtering} setFilters={setFiltering} />
      <TableCell />
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="name" staffs={staffs} />
      <DropCell field='staff__role' choices={SUPPLIER_ROLES} filters={filtering} setFilters={setFiltering} />
      <TableCell />
      <ByField field='at' label={'Огноо'} filters={filtering} setFilters={setFiltering} />
    </TableRow>
  );
}
