import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { BorderAll, Dehaze } from '@mui/icons-material'
import ToggleButton from '@mui/material/ToggleButton'

const alignChoices = ['box', 'list']
export default function ResultsToggleButton({ alignment, setAlignment }) {
	const handleChange = (newAlignment) => { setAlignment(newAlignment) }

	return (
		<ToggleButtonGroup
			color="secondary"
			value={alignment}
			exclusive
			onChange={(e, choice) => handleChange(choice)}
			aria-label="Alignment"
			size='small'
		>
			{
				alignChoices.map((choice, idx) => (
					<ToggleButton key={choice} value={choice} aria-label={choice}>
						{
							choice === 'box'
								?	<BorderAll sx={{ color: alignment === 'box' ? 'secondary.light' : 'initial'  }} />
								:	<Dehaze sx={{ color: alignment === 'list' ? 'secondary.light' : 'initial'  }} />
						}
					</ToggleButton>
				))
			}
		</ToggleButtonGroup>
	)
}