import Lobster from '../../../utils/fonts/Lobster.ttf'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

export default function LeftSection({ isAuthRoute }) {
    const navi = useNavigate()
    const handleClick = () => { navi('/') }
    const fontFace = `
        @fontFace {
        fontFamily: 'Lobster';
        src: url(${Lobster}) format('truetype');
    }`

    return (
        <Box
            sx={{ 
                width: '450px', cursor: 'inherit', '&:hover': { cursor: 'pointer' },
                ml: 1, flexDirection: 'row', display: 'flex', alignItems: 'center', 
                pt: isAuthRoute ? 1 : 0, pb: isAuthRoute ? 1 : 0,
                justifyContent: isAuthRoute ? 'center' : 'flex-start', 
            }}
            onClick={handleClick}
        >
            <img
                src='/Pharmo_white.png'
                width='40px'
                height='40px'
                style={{ marginRight: '10px', borderRadius: '10%' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                    fontSize='30px' 
                    fontWeight='bold'
                    fontStyle='italic' 
                    textAlign='center'
                    sx={{ 
                        fontFamily: 'Lobster', 
                        '@global': { '@fontFace': fontFace }, 
                        pt: 0, 
                        pb: '2px', 
                        lineHeight: '1.2em'
                    }}
                >Pharmo</Typography>
                {/* <Typography
                    fontSize='15px'
                    fontStyle='italic' textAlign='center'
                    component={'pre'}
                    sx={{
                        fontFamily: 'Lobster', fontWeight: 600,
                        '@global': { '@fontFace': fontFace },
                        lineHeight: '1.0em', pb: '6px'
                    }}
                >
                    {`Эмийн бөөний худалдаа,\n захиалгын систем`}
                </Typography> */}
            </Box>
        </Box>
    )
}
