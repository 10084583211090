import { Checkbox, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import { ActivateCell, ChoiceCell, MarkCell } from './Cell'
import { Delete, Edit } from '@mui/icons-material'
import { VerifyDialog } from '../../../../utils'
import { useState } from 'react'

export default function Row({ idx, row, cols, remove, getPromoToEdit }) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const handleDelete = () => { remove(row?.id); setDeleteDialog(false) }

    return (
        <TableRow sx={{ '& > td': { px: '6px', py: '2px', textAlign: 'center' } }}>
            <TableCell>{idx + 1}</TableCell>
            <TableCell sx={{ textAlign: 'left !important', minWidth: '200px' }}>{row?.name}</TableCell>
            <TableCell>{row?.code}</TableCell>
            <ActivateCell id={row?.id} isActive={row?.is_active} />
            <MarkCell id={row?.id} isMarked={row?.is_marked} />
            <ChoiceCell current={row?.promo_type} choices={cols?.find(c => c?.field_name === 'promo_type')?.choices||[]} />
            <ChoiceCell current={row?.target_type} choices={cols?.find(c => c?.field_name === 'target_type')?.choices||[]} />
            <TableCell>{row?.start_date?.split(' ')?.[0]||'-'}</TableCell>
            <TableCell>{row?.end_date?.split(' ')?.[0]||'-'}</TableCell>
            <TableCell>
                    <Checkbox
                        checked={Boolean(row?.is_cash)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
            </TableCell>
            <TableCell>{row?.created_at?.split(' ')?.[0]||'-'}</TableCell>
            <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip title='Засах' placement='left'>
                    <IconButton onClick={() => getPromoToEdit(row?.id)} size='small'>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Устгах' placement='left'>
                    <IconButton onClick={() => { setDeleteDialog(true) }} size='small'>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </TableCell>
            {deleteDialog && <VerifyDialog msg='Устгахдаа итгэлтэй байна уу?' open={deleteDialog} setOpen={setDeleteDialog} handleOk={handleDelete} />}
        </TableRow>
    )
}
