import PasswordField from '../../../../helpers/fields/PasswordField'
import { Box, Button, Dialog, Typography } from '@mui/material' 
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { removeTokens } from '../../../../../utils/functions'
import { changePassword } from '../../../../../api/auth'
import { useNavigate } from 'react-router'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'


const validationSchema = Yup.object({
    old_pwd: Yup.string()
            .required('Хуучин нууц үгээ оруулна уу!'),
    new_pwd: Yup.string()
            .required('Шинэ нууц үгээ үүсгэнэ үү!')
            .min(8, '8-с доошгүй тэмдэгтээс бүрдсэн байх')
            .matches(/[a-zA-Z]/, 'Зөвхөн англи үсэг ашиглана!')
            .minLowercase(1, '1-с доошгүй жижиг үсэг орсон байх')
            .minUppercase(1, '1-с доошгүй том үсэг орсон байх')
            .minNumbers(1, '1-с доошгүй тоо агуулсан байх')
            //.minSymbols(1, '1-с доошгүй тусгай тэмдэгт агуулсан байх: !?/-_ гэх мэт')
            .notOneOf([Yup.ref('old_pwd')], 'Шинэ болон хуучин нууц үгүүд ижил байна!'),
    new_pwd2: Yup.string().oneOf([Yup.ref('new_pwd'), null], 'Нууц үгүүд тохирохгүй байна!')
})

export default function ChangePwd() {
    const navi = useNavigate()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    // success
    const [success, setSuccess] = useState(false)
    const handleSuccessClose = () => {
        removeTokens()
        handleClose()
        navi('/home')
    }

    const { handleSubmit, getFieldProps, setFieldError, errors, touched } = useFormik({
        initialValues: {
            'old_pwd': '',
            'new_pwd': '',
            'new_pwd2': ''
        },
        onSubmit: (vals) => {
            const { old_pwd, new_pwd } = vals
            changePassword({ old_pwd, new_pwd }).then(res => {
                setSuccess(true)                              
            }).catch(e => {
                const errMsg = e?.response?.data
                if (errMsg?.includes('Хуучин нууц үг')) { setFieldError('old_pwd', errMsg) }
            })
        },
        validationSchema,
        validateOnChange: false
    })

    const getErrors = (name) => {
        const isErr = Object.keys(errors)?.includes(name) && Object.keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }
    
    return (
        <Box sx={{ p: 2 }}>
            <Button onClick={handleOpen} variant='contained'>Нууц үг солих</Button>
            {getToast(success, handleSuccessClose, 'Нууц үг амжилттай солигдлоо!')}
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 1, p: 3 }}>
                    <Typography textAlign='center'>Нууц үг солих</Typography>
                    <PasswordField
                        label='Хуучин нууц үг'
                        name='old_pwd'
                        {...getFieldProps('old_pwd')}
                        {...getErrors('old_pwd')}
                    />
                    <PasswordField
                        label='Шинэ нууц үг'
                        name='new_pwd'
                        {...getFieldProps('new_pwd')}
                        {...getErrors('new_pwd')}
                    />
                    <PasswordField
                        label='Шинэ нууц үг давтах'
                        name='new_pwd2'
                        {...getFieldProps('new_pwd2')}
                        {...getErrors('new_pwd2')}
                    />
                    <Button variant='contained' size='small' onClick={ handleSubmit }>
                        Баталгаажуулах
                    </Button>
                </Box>
            </Dialog>
        </Box>
    )
}