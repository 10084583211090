import ByPharmoUser from "./ByPharmoUser"
import TotalSales from "./TotalSales"
import ByProducts from "./ByProducts"
import ByCustomer from "./ByCustomer"
import ByProduct from "./ByProduct"
import { Box } from "@mui/material"
import BySeller from "./BySeller"

export default function ResultSection({ reportType, ym1, ym2, monthOrQuarter, product }) {
    return (
        <Box sx={{ p: 1 }}>
            {
                reportType === 'total_sales' && 
                <TotalSales
                    ym1={ym1}
                    ym2={ym2}
                    monthOrQuarter={monthOrQuarter}
                />
            }
            { 
                reportType === 'by_customer' && 
                <ByCustomer 
                    ym1={ym1}
                    ym2={ym2}
                    monthOrQuarter={monthOrQuarter}
                />
            }
            { 
                reportType === 'by_pharmo_user' && 
                <ByPharmoUser
                    ym1={ym1}
                    ym2={ym2}
                    monthOrQuarter={monthOrQuarter}
                />
            }
            { 
                reportType === 'by_seller' && 
                <BySeller
                    ym1={ym1}
                    ym2={ym2}
                    monthOrQuarter={monthOrQuarter}
                />
            }
            { 
                reportType === 'by_products' && 
                <ByProducts
                    ym1={ym1}
                    ym2={ym2}
                    monthOrQuarter={monthOrQuarter}
                />
            }
            { 
                reportType === 'by_product' && 
                <ByProduct
                    ym1={ym1}
                    ym2={ym2}
                    product={product}
                />
            }
        </Box>
    )
}
