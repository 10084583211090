import { getToast } from '../../../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../../../context/AuthCtx'
import { updateBasket } from '../../../../../api/basket'
import { Box, Button, TextField } from '@mui/material'
import { useState } from 'react'

export default function AddToBasketButton({ product_id, onSuccess = null }) {
    const { setBasket, setMsg, toastWait } = useAuthCtx()
    const [qty, setQty] = useState(1)
    const [value, setValue] = useState(qty)

    const [success, setSuccess] = useState(null)
    const [err, setErr] = useState(null)

    const handleValue = (e) => {
        const newVal = e?.target?.value
        setValue(newVal)
        if (!newVal) { setErr('Утга оруулна уу!') }
        else if (isNaN(newVal)) { setErr('Тоон утга оруулна уу!') }
        else if (newVal <= 0) { setErr('1-с багагүй утга оруулна уу!') }
        else { setQty(newVal); setErr(null) }
    }

    const handleUpdate = () => {
        if (!err) {
            updateBasket({ product_id, qty })
                .then(res => {
                    const d = res?.data
                    if (d?.available_qty) {
                        setMsg({m: `Боломжит үлдэгдэл: ${d.available_qty}`, s: 'warning'})
                    } else if (d?.available_qty === 0) {
                        setMsg({m: 'Бараа дууссан байна!', s: 'warning'})
                    }
                    // if success
                    if (d?.id && d?.totalPrice) {
                        setBasket(d)
                        setMsg({ m: 'Сагсанд амжилттай нэмэгдлээ!', s: 'success' })
                        if (onSuccess) { onSuccess() }
                    }
                })
                .catch(e => { console.log("ERR: ", e); toastWait() })
        }
    }

    const handleEnter = (e) => { if (e.code === 'Enter') { handleUpdate() } }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0, justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            { getToast(Boolean(err), () => setErr(null), err, 'warning') }
            { getToast(Boolean(success), () => setSuccess(null), success, 'success') }
            <TextField
                label='Тоо, ширхэг'
                sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                    "& input[type=number]": { MozAppearance: "textfield" },
                    width: '170px'
                }}
                autoFocus
                value={ value }
                onChange={ handleValue }
                type='number'
                inputProps={{ style: { textAlign: 'center', padding: 0, fontSize: '20px' } }}
                onKeyDown={ handleEnter }
                variant='standard'
                size='small'
                error={Boolean(err)}
                helperText={Boolean(err) ? err : ' '}
            />
            <Button
                variant='contained'
                onClick={ handleUpdate }
                size='small'
                sx={{ 
                    mb: '10px', width: '160px',
                    borderRadius: 0,
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': { backgroundColor: 'primary.main' }
                }}
            >Сагсанд нэмэх</Button>
        </Box>
    )
}