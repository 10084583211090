import { AppBar, Box, Container, Link, Toolbar, Typography } from "@mui/material"
import Lobster from '../../../utils/fonts/Lobster.ttf'

export default function Navbar() {
    const isHome = window.location.pathname === '/home'
    const fontFace = `
        @fontFace {
        fontFamily: 'Lobster';
        src: url(${Lobster}) format('truetype');
    }`
    return (
        <AppBar variant="dense" sx={{ boxShadow: 3}}>
            <Toolbar>
                <Container
                    maxWidth='xl' 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                        <img 
                            src='/Pharmo_white.png' 
                            alt='phamro_logo' 
                            style={{ 
                                width: '30px', 
                                height: '30px',
                                borderRadius: '10%',
                            }} 
                        />
                        <Typography
                            fontSize='30px' 
                            fontWeight='bold'
                            fontStyle='italic' 
                            textAlign='center'
                            sx={{ 
                                fontFamily: 'Lobster', 
                                '@global': { '@fontFace': fontFace }, 
                                pt: 0, 
                                pb: '2px', 
                                lineHeight: '1.2em'
                            }}
                        >Pharmo</Typography>
                    </Box>
                    {
                        isHome &&
                        <Box sx={{  display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 'auto' }}>
                            <Link href='/signin' sx={{ color: 'white', ml: 2 }}>Нэвтрэх</Link>
                            <Typography sx={{ ml: 1 }}>/</Typography>
                            <Link href='/signup' sx={{ color: 'white', ml: 2 }}>Бүртгүүлэх</Link>
                        </Box>
                    }
                </Container>
            </Toolbar>
        </AppBar>
    )
}
