import { useEffect, useLayoutEffect, useRef } from "react"
import { Paper, Typography } from "@mui/material"

export default function ListComboBox({ props, allCount, currentCount, hasNextPage, setPage }) {
    const paperRef = useRef(null)
    const handleScroll = () => {
        const element = paperRef.current
        if (element) {
            const { scrollTop, scrollHeight, clientHeight } = element
            window.localStorage.setItem('product_search_scrollTop', scrollTop)
            if (scrollTop + clientHeight >= scrollHeight) {
                if (hasNextPage) {
                    setPage(prev => prev + 1)
                }
            }
        }
    }

    useLayoutEffect(() => {
        const element = paperRef.current
        if (element) {
            const st = window.localStorage.getItem('product_search_scrollTop')
            if (st) { element.scrollTop = st }
        }
    }, [])

    useEffect(() => {
        const element = paperRef.current
        if (element) { 
            element.addEventListener('scroll', handleScroll)
        }
        return () => { if (element) { element.removeEventListener('scroll', handleScroll) } }
    }, [])

    return (
        <Paper
            ref={paperRef}
            {...props}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 600,
                boxShadow: 10,
                overflowY: 'auto',
                position: 'relative',
            }}
        >
            {
                Boolean(allCount) &&
                <Typography 
                    sx={{
                        position: 'sticky', 
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        boxShadow: 5,
                        background: 'white',
                        borderRadius: '5px',
                        fontSize: '14px',
                        fontWeight: 600,
                        fontStyle: 'italic',
                        mx: 1,
                        pl: 1,
                        width: 'fit-content',
                    }}
                >
                    Нийт {allCount} илэрц{allCount === currentCount ? ' байна.' : `ээс ${currentCount} хүртэлхийг харуулж байна.`} 
                </Typography>
            }
            {props.children}
        </Paper>
    )
}