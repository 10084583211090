import { CssBaseline, ThemeProvider } from '@mui/material'
import { getThemeName } from '../utils/functions'
import { themes } from '../global/themes'
import { useAuthCtx } from './AuthCtx'

const selectThemeByName = (themeName) => themes.find(theme => theme['name'] === themeName)?.['theme']

const ThemeCtx = ({children}) => {
		const { selectedTheme } = useAuthCtx()
		const defaultTheme = selectThemeByName('PharmoTheme')

		return (
			<ThemeProvider theme={selectThemeByName(selectedTheme??getThemeName()) || defaultTheme}>
					<CssBaseline />
					{children}
			</ThemeProvider>
		)
}

export default ThemeCtx