import { Box, Dialog, Link, Tab, Tabs } from '@mui/material'
import TempletList from './TempletList'
import SaveTemplet from './SaveTemplet'
import { useState } from 'react'

export default function BasketTemplet({ basket, setBt }) {
    const [box, setBox] = useState(null)
    const boxOn = () => { setBox(true) }
    const boxOff = () => { setBox(null) }

    const [tab, setTab] = useState(1)
    const handleChange = (e, newVal) => { setTab(newVal) }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mr: 3 }}>
            <Link href='#' onClick={boxOn} sx={{ fontSize: '14px', fontWeight: 'bold', fontStyle: 'italic', color: 'secondary.light' }}>Сагсны мэдээлэл хадгалах</Link>
            <Dialog open={Boolean(box)} onClose={boxOff}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label='Сагс хадгалах' value={1} />
                    <Tab label='Хадгалсан сагснууд' value={2} />
                </Tabs>
                <Box>
                { tab === 1 ? <SaveTemplet basket={basket} setBt={setBt} boxOff={boxOff} /> : <TempletList />}
                </Box>
            </Dialog>
        </Box>
    )
}
