import FilterSection from "./FilterSection"
import ResultSection from "./ResultSection"
import { Box } from "@mui/material"
import { useState } from "react"

export default function SalesReport() {
    const [reportType, setReportType] = useState('total_sales')

    const currentYear = new Date().getFullYear()
    const [ym1, setYm1] = useState({ year: currentYear, month: 1 }) // year/month1
    const [ym2, setYm2] = useState({ year2: null, month2: null }) // year/month2
    const [monthOrQuarter, setMonthOrQuarter] = useState('month')

    const [product, setProduct] = useState(null)
    //console.log("P: ", product)

    return (
        <Box>
            <FilterSection
                reportType={reportType} 
                setReportType={setReportType}
                currentYear={currentYear}
                ym1={ym1}
                setYm1={setYm1}
                ym2={ym2}
                setYm2={setYm2}
                monthOrQuarter={monthOrQuarter}
                setMonthOrQuarter={setMonthOrQuarter}
                setProduct={setProduct}
            />
            <ResultSection
                reportType={reportType}
                ym1={ym1}
                ym2={ym2}
                monthOrQuarter={monthOrQuarter}
                currentYear={currentYear}
                product={product}
            />
        </Box>
    )
}
