import { useEffect, useRef, useState } from "react"

export const qtyMsgs = {
    added: 'Сагсанд амжилттай нэмэгдлээ!',
    removed: 'Сагснаас амжилттай хасагдлаа!',
    lessQty: 'Үлдэгдэл хүрэлцэхгүй байна.',
    noQty: 'Бараа дууссан байна!',
    tryErr: 'Түр хүлээгээд дахин оролдоно уу!'
}

// Count Input
export const useCountInput = ({ setCount, handleClick = () => {}, count = 1 }) => {
    const [err, setErr] = useState(null)
    const [value, setValue] = useState(count)

    const handleValue = (e) => {
        const newVal = e?.target?.value
        setValue(newVal)
        if (!newVal) { setErr('Утга оруулна уу!') }
        else if (isNaN(newVal)) { setErr('Тоон утга оруулна уу!') }
        else if (newVal <= 0) { setErr('1-с багагүй утга оруулна уу!') }
        else { setCount(newVal); setErr(null) }
    }

    const handleAdd = () => { if (count && !err) { handleClick() } }
    const handleEnter = (e) => { if (e.code === 'Enter' && count && !err) { handleClick() } }

    return { err, value, handleValue, handleAdd, handleEnter }
}

// Table Pagination
export const useTablePagination = (rppOptions) => {
    const [page, setPage] = useState(1)
    const handlePageChange = (e, newPage) => { setPage(newPage) }
    const [pageSize, setPageSize] = useState(rppOptions[0])
    const handlePageSizeChange = (e) => {
        setPage(1)
        setPageSize(parseInt(e.target.value))
    }
    return [ page, handlePageChange, pageSize, handlePageSizeChange, rppOptions ]
}

// get external component's rect
export const useExternalComponentRect = ({ externalRef }) => {
    const [rect, setRect] = useState(null)
    const resizeObserver = useRef(null)
    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            setRect(entries[0].contentRect)
        })

        if (externalRef.current) { resizeObserver.current.observe(externalRef.current) }
        return () => { if (resizeObserver.current) { resizeObserver.current.disconnect() } }
    }, [externalRef])
    return rect||{}
}
