import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material/'
import { getToast } from '../../auth/helpers/ToastAlert'
import { scrollStyle } from '../../../utils/constants'
import { useAuthCtx } from '../../../context/AuthCtx'
import { useEffect, useState } from 'react'
import BasketTemplet from './BasketTemplet'
import { useLocation } from 'react-router'
import { sortBy } from 'underscore'
import { CustomDivider } from '..'
import Footer from './Footer'
import Row from './Row'

// Үндсэн компонэнт
export default function OrderItems() {
    const { basket } = useAuthCtx()
    const basketItems = sortBy(basket?.items, 'id')||[]
    const { totalPrice, totalCount } = basket ? basket : {}
    // save temporarily
    useEffect(() => {
        if (totalPrice) { window.localStorage.setItem('tp', totalPrice) }
        if (totalCount) { window.localStorage.setItem('tc', totalCount) }
    }, [totalPrice, totalCount])

    // барааны үлдэгдэл хүрэлцэхгүй эсвэл бараа дууссан эсэх мэдээлэл
    const [qd, setQd] = useState(null)

    const loc = useLocation()
    const fromTemplet = loc?.state?.fromTemplet

    // basket templet saved
    const [bt, setBt] = useState(null)
    const btOff = () => { setBt(null) }

    return (
        <>
            <CustomDivider text={'Барааны тоо, ширхэг'} />
            { getToast(Boolean(bt), btOff, bt, 'success', 1500) }
            {
                (basketItems?.length > 0)
                    ?   <Box>
                            <TableContainer sx={{ width: '100%', ...scrollStyle, display: 'flex', justifyContent: 'center' }}>
                                <Table stickyHeader size='small' sx={{ width: '90%' }}>
                                    <TableHead>
                                        <TableRow sx={{ '& > th': { py: '2px', px: '6px', pl: '4px', textAlign: 'center', fontSize: '15px', fontWeight: 'bold', fontStyle: 'italic', color: 'secondary.light' } }}>
                                            <TableCell width='30px'>№</TableCell>
                                            <TableCell sx={{ textAlign: 'left !important' }}>Барааны нэр</TableCell>
                                            <TableCell sx={{ textAlign: 'right !important', pr: '30px !important', width: '150px' }}>Үнэ</TableCell>
                                            {/* <TableCell width='130px'>Хямдралтай үнэ</TableCell> */}
                                            {/* <TableCell width='130px'>Бөөний үнэ</TableCell> */}
                                            <TableCell width='100px'>Тоо, ширхэг</TableCell>
                                            <TableCell sx={{ textAlign: 'right !important', pr: '30px !important', width: '150px' }}>Нийт үнэ</TableCell>
                                            <TableCell width='30px' />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            basketItems.map( (b, idx) => {
                                                    return <Row key={b.id} item={b} idx={idx} />
                                                } 
                                            )
                                        }
                                    </TableBody>
                                    <Footer totalCount={totalCount} totalPrice={totalPrice} />
                                </Table>
                            </TableContainer>
                            { !fromTemplet && <BasketTemplet basket={basket} setBt={setBt} /> }
                        </Box>
                    :   <div></div>
            }
        </>
    )
}