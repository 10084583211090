import { scrollStyle } from '../../../../../../utils/constants'
import { toPrice } from '../../../../../../utils/functions'
import { clearBasket } from '../../../../../../api/basket'
import { Box, Button, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import BasketItem from './BasketItem'
import { sortBy } from 'underscore'

export default function BasketPanel({ basket, setBasket, goToOrder, setMsg }) {
    const basketItems = basket?.items.length > 0 ? sortBy(basket.items, 'id') : []
    const loc = useLocation()
    const navi = useNavigate()
    const goToHome = () => {
        if (loc.pathname === '/order') { navi('/') }
    }
    const handleClearBasket = () => {
        clearBasket(basket.id)
            .then(res => { 
                setBasket(res?.data);
                goToHome()
            })
            .catch(err => { 
                console.log("clearBasket ERR: ", err); 
                setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) 
            })
    }

    return (
        <Box sx={{ borderRadius: 1, backgroundColor: '#f1f1f1' }}>
            <Box>
                <Box
                    sx={{
                        maxHeight: '350px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        borderBottom: '1px solid',
                        borderBottomColor: 'primary.light',
                        ...scrollStyle,
                        "&::-webkit-scrollbar": { width: 6 }
                    }}
                >
                    {
                        basketItems?.map((item, idx) => 
                            <BasketItem 
                                key={`key_${item.id}`} 
                                item={item} 
                                idx={idx}
                                setMsg={setMsg}
                                setBasket={setBasket}
                            />
                        ) 
                    }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', pr: 1, pl: 1, mt: 1 }}>
                    <Typography fontSize='15px' color='secondary.light' fontWeight='bold'>Нийт дүн: </Typography>
                    <Typography fontSize='15px' color='secondary.light' fontWeight='bold' sx={{ pr: 4 }}>{toPrice(basket?.totalPrice||0)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                        size='small'
                        onClick={() => { goToOrder() }}
                        sx={{ borderRadius: 0 }}
                        variant='contained'
                    >
                        Захиалга үүсгэх
                    </Button>
                    <Typography 
                        sx={{ 
                            color: 'secondary.light', 
                            textDecoration: 'underline', 
                            cursor: 'pointer',
                            fontSize: '13px',
                            my: 1, 
                        }}
                        onClick={handleClearBasket}
                    >Сагс хоослох</Typography>
                </Box>
            </Box>
        </Box>
    )
}
