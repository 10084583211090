import excel_logo from './excel_logo.png'
import { Button } from '@mui/material'
import ExcelJS from 'exceljs'

export default function ExportAsExcel({ 
  tableData, 
  tableHeader, 
  mergedCells,
  mergeCellRowCount,
  mergedCellValue, 
  labelName = 'month', 
  fileName = 'pharmo_sales_report',
  sheetName = null
}) {
	const handleExport = async () => {
		const workbook = new ExcelJS.Workbook()
		const worksheet = workbook.addWorksheet(sheetName||fileName)
		worksheet.columns = [
			{ header: tableHeader[0], key: 'col1', width: 40 },  // Month column - wider for dates
			{ header: tableHeader[1], key: 'col2', width: 15 },  // Count column
			{ header: tableHeader[2], key: 'col3', width: 18 },  // Total column - wider for numbers
		]

		let currentRow = 1 // table header

		// Border style definition - will be used for all cells
		const borderStyle = {
			top: { style: 'thin' },
			left: { style: 'thin' },
			bottom: { style: 'thin' },
			right: { style: 'thin' }
		}

		// Handle merged cells first if they exist
		if (mergedCells) {
			worksheet.mergeCells(mergedCells)
			const mergedCellStart = mergedCells.split(':')[0]
			worksheet.getCell(mergedCellStart).value = mergedCellValue
			worksheet.getCell(mergedCellStart).font = { bold: true, size: 15 }
			worksheet.getCell(mergedCellStart).border = borderStyle
			worksheet.getCell(mergedCellStart).alignment = { vertical: 'middle', horizontal: 'center' }
			worksheet.getCell(mergedCellStart).fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'F2F2F2' }
			}
			currentRow += mergeCellRowCount
		}

		// Add regular headers
		worksheet.addRow(tableHeader)
		
		// Style regular headers
		const headerRow = worksheet.getRow(currentRow)
		headerRow.eachCell((cell) => {
			cell.font = { bold: true }
			cell.alignment = { vertical: 'middle', horizontal: 'center' }
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'FFE2EFDA' }  // Light green
			}
			cell.border = borderStyle
		})

		// Add data rows
		tableData.forEach((row) => {
			const dataRow = [
				row?.[labelName],
				row.count,
				row.total
			]
			worksheet.addRow(dataRow)
		})

		// Style data cells
		worksheet.eachRow((row, rowNumber) => {
			if (rowNumber > currentRow) { // Skip header rows
				row.eachCell((cell) => {
					cell.alignment = {
						vertical: 'middle', 
						horizontal: cell.col === 1 ? 'left' : cell.col === tableHeader.length ? 'right' : 'center', 
						wrapText: true, 
						indent: [1, tableHeader.length].includes(cell.col) ? 1 : 0 
					};
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: 'FFFFFFFF' }  // White
					};
					cell.border = borderStyle;
					cell.numFmt = cell.col === tableHeader.length ? '#,##0.00 ₮' : null
				})
			}
		})

		// Generate buffer and download
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
		const url = window.URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.href = url
		link.download = `${fileName}.xlsx`
		link.click()
		window.URL.revokeObjectURL(url)
	}

	return (
		<Button 
			onClick={handleExport}
			variant='outlined'
			size='small'
			sx={{ mb: 1 }}
		>
			<span style={{ marginRight: '5px' }}>Export</span>
			<img
				src={excel_logo}
				alt='excel_logo.png'
				style={{ 
					width: '20px',
					height: '20px',
				}}
			/>
		</Button>
	)
}