import { Box, IconButton, TableCell, TextField, Tooltip, useTheme } from '@mui/material'
import { getCustomerCredit } from '../../../../../../../api/company'
import { toPrice } from '../../../../../../../utils/functions'
import { Edit, Save } from '@mui/icons-material'
import { keys } from 'underscore'
import { useState } from 'react'

export default function DebtLimitCell({ row, setPartners }) {
    const [cred, setCred] = useState(row?.credit || 0)
    const theme = useTheme()
    const pl = theme.palette.primary.light
    const sl = theme.palette.secondary.light
    const handleChange = (e) => {
        if (/^\d+$/.test(e.target.value)) {
            setCred(e.target.value)
        }
    }
    const [isEditing, setIsEditing] = useState(false)
    const handleEdit = () => { setIsEditing(!isEditing) }
    const handleSave = () => {
        try {
            // console.log(row.id);
            // console.log(cred);
            getCustomerCredit(row?.id, { credit: cred })
                .then(res => {
                    if (res?.data && keys(res.data).includes('credit')) {
                        setCred(res.data.credit || 0.00);
                        setPartners(prev => prev.map(p => p.id === row?.id ? { ...p, ...res.data } : p) || null);
                        setIsEditing(false);
                    }
                })
                .catch(e => {
                    console.log("ERR: ", e)
                })
        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = () => { isEditing ? handleSave() : handleEdit() }
    const handleEnter = (e) => { if (e.key === 'Enter') handleSave() }

    return (
        <TableCell width={125}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <TextField
                    disabled={!isEditing}
                    value={isEditing ? parseInt(cred) : `${toPrice(cred)}`}
                    onChange={handleChange}
                    size='small'
                    inputProps={{ style: { padding: '2px', paddingLeft: '8px', fontSize: '15px' } }}
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: pl,
                        },
                    }}
                    onKeyDown={handleEnter}
                />
                <Tooltip title={isEditing ? 'Хадгалах' : 'Өөрчлөх'} placement='right'>
                    <IconButton sx={{ p: '4px' }} onClick={handleClick}>
                        {isEditing ? <Save sx={{ color: sl }} /> : <Edit sx={{ color: sl }} />}
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>
    )
}
