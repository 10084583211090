import AddToBasketButton from '../../../../ProductDetail/ProductInfo/AddToBasketButton'
import { Box, Dialog, Typography } from '@mui/material'
import { useState } from 'react'
import { btnStyle } from '..'

export default function AddToBasketWithQty ({ product_id, isHover, setIsHover }) {
    const cartStyle = { ...btnStyle, right: isHover ? -18 : -5 }
    // handle modal
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false); setIsHover(false); }

    return (
        <Box
            sx={{ 
                display: 'flex', transition: 'all 0.2s linear',
                position: 'relative', opacity: isHover ? '100%' : '0%'
            }}
        >
            <Typography
                onClick={handleOpen}
                sx={{ ...cartStyle, p: '2px 4px' }}
            >
                Захиалах тоо
            </Typography>
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ p: 2, pl: 4, pr: 4 }}>
                    <AddToBasketButton product_id={product_id} onSuccess={handleClose} />
                </Box>
            </Dialog>
        </Box>
    )
}