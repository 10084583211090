import { Table, TableBody, TableContainer, TableHead, TableRow, Typography, TableCell, useTheme } from '@mui/material'
import TablePaginationFooter from '../../common/TablePaginationFooter'
import { paperStyle, scrollStyle } from '../../../utils/constants'
import { getOrders } from '../../../api/pharmacy'
import { useQuery } from 'react-query'
import { useState } from 'react'
import Filter from './Filter'
import Row from './Row'

const headCellStyle = { backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' }

const Orders = () => {
    const theme = useTheme()
    const rppOptions = [10, 25, 50]
    const [page, setPage] = useState(1)
    const handlePage = (page) => { setPage(page) }
    const [pageSize, setPageSize] = useState(10)
    const handlePageSize = (pageSize) => { setPageSize(pageSize) }

    const [filters, setFilters] = useState(null)
    const { data } = useQuery(['getPharmacyOrders', filters, pageSize, page], getOrders)
    const { count, orders } = data?.data || {}

    return (
            <TableContainer sx={{ width: '90%', ...paperStyle, maxHeight: '650px', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
                <Table aria-label="simple table" sx={{ fontSize: '12px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...headCellStyle, width: '15px', textAlign: 'center', p: '3px' }}>№</TableCell>
                                {
                                    ['', 'Захиалгын дугаар', 'Захиалгын дүн', 'Барааны тоо, ширхэг', 'Захиалгын төлөв', 'Төлбөрийн хэлбэр','Захиалгын явц', 'Хүргүүлэх салбар', 'Нийлүүлэгч', 'Огноо']
                                        .map(f => <TableCell sx={{ ...headCellStyle, p: '2px', textAlign: 'center' }}>{f}</TableCell>)
                                }
                        </TableRow>
                    </TableHead>
                    <Filter filters={filters} setFilters={setFilters} />
                    <TableBody>
                        {
                            orders?.length > 0
                                ?   orders?.map((row, idx) => <Row key={row?.id} row={row} idx={idx} page={page} pageSize={pageSize} />)
                                :   <TableCell colSpan={12}>
                                        <Typography fontSize='16px' fontWeight='bold' textAlign='center' sx={{ color: theme.palette.primary.light }}>Үр дүн олдсонгүй!</Typography>
                                    </TableCell>
                        }
                    </TableBody>
                    {orders?.length > 0 
                        && <TablePaginationFooter
                                rppOptions={rppOptions}
                                count={count}
                                page={page}
                                handlePage={handlePage}
                                pageSize={pageSize}
                                handlePageSize={handlePageSize}
                            />}
                </Table>
            </TableContainer>
    )
}

export default Orders