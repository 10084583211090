import { removeTokens } from "../utils/functions"
import axios from "axios"
import { getDeviceInfo } from "./utils"

export const mainUrl = process.env.REACT_APP_MAIN_API
export const apiUrl = `${mainUrl}:${process.env.REACT_APP_API_PORT}`
export const baseUrl = `${apiUrl}/api/v1/`
export const macsInStockUrl = process.env.REACT_APP_MACS_INSTOCK_URL

const navToHome = () => { window.location.href = '/home' }

const headers1 = {
	'Content-Type': 'application/json',
	'Accept': 'application/json',
}
const headers = {
	...headers1,
	'X-Pharmo-Client': `${process.env.REACT_APP_X_PHARMO_CLIENT_HEADER}`,
}
//console.log("GDI: ", `${process.env.REACT_APP_X_PHARMO_CLIENT_HEADER}`)

export const Axios = axios.create({
	baseURL: baseUrl,
	//timeout: 8000,
	headers,
})

export const AxiosWithoutCustomHeader = axios.create({
	baseURL: baseUrl,
	//timeout: 8000,
	headers1,
})

Axios.interceptors.request.use(
	config => {
		if (/\bcompany\//.test(config.url)) {
			config.headers['Content-Type'] = 'multipart/form-data'
		}
		return config
	},
)

const AxiosWithToken = axios.create({
	baseURL: baseUrl,
	headers,
})

AxiosWithToken.interceptors.request.use(
	config => {
		const token = localStorage.getItem("access_token")
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
			if (/\bproduct\//.test(config.url)) {
				config.headers['Content-Type'] = 'multipart/form-data'
			}
		} else { navToHome(); removeTokens(); }
		return config
	},
	error => Promise.reject(error)
)

AxiosWithToken.interceptors.response.use(
	response => response,
	async function (error) {
		const originalRequest = error.config
		if (typeof error.response === 'undefined') {
			alert( 'Уучлаарай, сервер хандах боломжгүй байна. Түр хүлээгээд дахин оролдоно уу!');
			return Promise.reject(error)
		}
		const erd = error?.response?.data
		if (erd?.code === "user_inactive" || erd?.code === 'token_not_match') {// logout if user is inactive or token not match
			removeTokens()
			navToHome()
		}
		if ( erd?.code === 'token_not_valid' && error.response.status === 401 && error.response.statusText === 'Unauthorized' ) {
			const refreshToken = localStorage.getItem('refresh_token')
			if (refreshToken) {
				try {
					const response = await Axios.post('auth/refresh/', { refresh: refreshToken })
					if (response.data.access) {
						localStorage.setItem('access_token', response.data.access)
						window.dispatchEvent(new CustomEvent('accessTokenChange', { detail: { access: response.data.access } }))
					}
					AxiosWithToken.defaults.headers['Authorization'] = 'Bearer ' + response.data.access_token
					originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token
					return AxiosWithToken(originalRequest)
				} 
				catch (error) {
					removeTokens()
					navToHome()
				}
			} 
			else { 
				removeTokens()
				navToHome()
			}
		}
		return Promise.reject(error)
	}
)

export default AxiosWithToken