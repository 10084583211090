import { Button, Checkbox, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { isTokenValid, toPrice } from '../../../../../../utils/functions'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { useProductListCtx } from '..'
import { isEmpty } from 'underscore'

export default function ProductTableBody ({ setOpen, products, colFields, filters, toDelIds, setToDelIds, setSelectedProduct, count, page, pageSize, isFetching, handleAddOpen }) {
    const { cats } = useProductListCtx()
    const { macs } = isTokenValid()
    const pf = ['price', 'sale_price']
    const handleChecked = (e, pId) => {
        e.stopPropagation()
        toDelIds.includes(pId)
            ?   setToDelIds(prev => ([...prev.filter(id => id !== pId)]))
            :   setToDelIds(prev => ([...prev, pId]))
    }
    const isIncluded = (pId) => toDelIds.includes(pId)

    const handleEditOpen = (p) => {
        setSelectedProduct(p)
        setOpen(true)
    }

    return (
        <TableBody sx={{ border: 'none' }}>
            {
                (products?.length && !isFetching)
                    ?   products.map(
                            (p, idx) => (
                                <TableRow
                                    key={p.id}
                                    sx={{
                                        '&:hover': { backgroundColor: '#f2f2f2', cursor: 'pointer' },
                                        maxHeight: '50px', minHeight: '50px', overflow: 'hidden',
                                        '& td': { p: '3px', fontSize: '14px', maxHeight: '40px', height: '40px' }
                                    }}
                                    onClick={() => { handleEditOpen(p) }}
                                >
                                    <TableCell sx={{ padding: 0 }}>
                                        <Checkbox
                                            checked={ isIncluded(p.id) }
                                            sx={{ p: 0, display: 'flex', justifyContent: 'center' }}
                                            checkedIcon={ <CheckBox sx={{ '&.MuiSvgIcon-root': { fontSize: '15px' } }} /> }
                                            icon={ <CheckBoxOutlineBlank sx={{ '&.MuiSvgIcon-root': { fontSize: '15px' } }} /> }
                                            onClick={e => { handleChecked(e, p.id) }}
                                        />
                                    </TableCell>
                                    <TableCell>{ (count > pageSize && page !== 1) ? idx + 1 + page * pageSize : idx + 1 }</TableCell>
                                    {
                                        colFields?.map(field => 
                                            (
                                                <TableCell key={field} sx={{ textAlign: field !== 'name' ? 'right' : 'left' }}>
                                                    <div style={{ maxHeight: field != 'name' ? '60px' : 'fit-content', overflow: 'hidden' }}>
                                                        { 
                                                            field === 'category'
                                                                ?   cats?.filter(c => p[field]?.includes(c.pk)).map(c => c?.name).join(', ')
                                                                :   pf.includes(field)
                                                                        ?   toPrice(p[field])
                                                                        :   ['vndr', 'mnfr'].includes(field)
                                                                                ?   p[field]?.name
                                                                                :   p[field]
                                                        }
                                                    </div>
                                                </TableCell>
                                            )
                                        )
                                    }
                                </TableRow>
                            ))
                    :   <TableCell colSpan={colFields?.length + 2} sx={{ p: 2, textAlign: 'center' }}>                            
                            {
                                isEmpty(filters)
                                    ?   macs
                                            ?   <Typography>MACS системийг хэрэглэгч байгууллагын хувьд MACS-с барааны мэдээллээ оруулна.</Typography>
                                            :   <Button size='small' variant='contained' onClick={handleAddOpen} >Бараа нэмэх</Button>
                                    :   <Typography sx={{ color: 'primary.main', mb: 2, mt: 3 }}>Тохирох барааны мэдээлэл олдсонгүй!</Typography>
                            }
                        </TableCell>
                }
        </TableBody>
    )
}