import Resizer from 'react-image-file-resizer'

export const isObj = (obj) => Boolean(obj && Object.keys(obj)?.length)

export const setTokens = (access, refresh) => {
	localStorage.setItem('access_token', access)
	localStorage.setItem('refresh_token', refresh)
    window.dispatchEvent(new CustomEvent('accessTokenChange', { detail: { access } }))
}
export const removeTokens = () => {
	localStorage.removeItem('access_token')
	localStorage.removeItem('refresh_token')
    window.dispatchEvent(new CustomEvent('accessTokenChange', { detail: { access: null } }))
}

export const isTokenValid = (token = null) => {
	const at = token ? token : window.localStorage.getItem('access_token')
	if (at) {
		try {
			return JSON.parse(atob(at.split('.')[1]))
		} 
        catch (e) { 
            return false 
        }
	}
	return false
}

export const isRefreshValid = () => {
	const at = window.localStorage.getItem('refresh_token')
	if (at) {
		try {
			return JSON.parse(atob(at.split('.')[1]))
		} 
        catch (e) { 
            return false 
        }
	}
	return false
}

// theme name from local storage
export const getThemeName = () => localStorage.getItem('themeName') ? JSON.parse(localStorage.getItem('themeName')) : null

// үнийн формат
export const toPrice = (price) => {
  return `${
              new Intl.NumberFormat(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
              }).format(price)
          } ₮`
}

// password validation
export const checkPass = (pass, minLength) => pass?.length >= minLength && /\d/.test(pass) && /[A_Z]/.test(pass) && /[a-z]/.test(pass)
export const checkEmail = (email) => /^\S+@\S+\.\S+$/.test(email)

// compress
const imgTypes = {
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/webp': 'webp'
}
export const compressImgs = (prevs, imgs, cb) => {
    return new Promise((resolve, reject) => {
        const promises = imgs.map(img => {
            return new Promise((innerResolve, innerReject) => {
                Resizer.imageFileResizer(img, 650, 650, imgTypes[img.type], 80, 0, i => {
                    innerResolve(i)
                }, 'file')
            })
        })

        Promise.all(promises)
            .then(compressed => { resolve(cb([...prevs, ...compressed])) })
            .catch(error => { reject(error) })
    })
}

export const compressImage = (image, cb) => {
    return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
            image,
            650, // maxWidth
            650, // maxHeight
            'JPEG', // compressedType
            80, // quality (0-100)
            0, // rotation
            (compressedImage) => { resolve(cb(compressedImage)) },
            'file'
        )
    })
}

export const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // Check for mobile devices
    if (/android/i.test(userAgent)) {
        return true
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true
    }
    return false
}

export const isDesktopMode = () => {
    // Check if the viewport width is greater than a typical mobile width
    return window.innerWidth > 768
}