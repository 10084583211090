import SimpleImageSlider from 'react-simple-image-slider'

const images = [
    {
        url: '/images/img1.jpg',
        link: '/home'
    },
    {
        url: '/images/img2.jpg',
        link: '/home'
    },
    {
        url: '/images/img3.jpg',
        link: '/home'
    }
]

export default function SliderBox() {
    const handleClick = (idx, e) => {
        window.location.href = Object.keys(images[idx]).includes('link') ? images[idx]['link'] : 'home'
    }
    return (
        <div style={{ width: 'calc(100% - 540px)', height: '92%', display: 'flex', flexGrow: 1, m: 0, p: 0 }}>
            <SimpleImageSlider
                images={images}
                width={'calc(100% - 580px)'}
                height={'92%'}
                showBullets={true}
                showNavs={true}
                autoPlay={true}
                onClick={handleClick}
                style={{ borderRadius: '5px', margin: '0', padding: '0' }}
            />
        </div>
    )
}
