import { removeFromBasket, updateBasket } from '../../../../../../api/basket'
import { useAuthCtx } from '../../../../../../context/AuthCtx'
import { Box, Typography } from '@mui/material'
import { btnStyle } from '..'

export default function AddToBasket ({ product, isHover }) {
    const { basket, setBasket, setMsg, toastWait } = useAuthCtx()
    const item = basket?.items?.find(i => i.product_id === product?.id)
    const handleAdd = () => {
        updateBasket({ product_id: product?.id, qty: 1 })
            .then(res => {
                const d = res?.data
                if (d?.available_qty) {
                    setMsg({m: `Боломжит үлдэгдэл: ${d.available_qty}`, s: 'warning'})
                } else if (d?.available_qty === 0) {
                    setMsg({m: 'Бараа дууссан байна!', s: 'warning'})
                }
                // if success
                if (d?.id && d?.totalPrice) {
                    setBasket(d)
                    setMsg({ m: 'Сагсанд амжилттай нэмэгдлээ!', s: 'success' })
                }
            })
            .catch(e => { console.log("ERR: ", e); toastWait() })
    }
    const handleRemove = () => {
        removeFromBasket(item?.id)
            .then(res => {
                const newBask = res?.data
                if (newBask) { setBasket(newBask) } else { setBasket(null) }
            })
            .catch(e => { console.log("ERRs: ", e); toastWait() })
    }

    const handleClick = () => { item ? handleRemove() : handleAdd() }

    const cartStyle = { ...btnStyle, left: isHover ? -20 : -7 }

    return (
        <Box
            sx={{ 
                display: 'flex',
                transition: 'all 0.2s linear',
                position: 'relative',
                opacity: isHover ? '100%' : '0%',
            }}
        >
            <Typography
                onClick={handleClick}
                sx={{ ...cartStyle, p: '2px 4px' }}
            >{'Сагс' + `${ item ? 'наас хасах' : 'анд нэмэх' }`}</Typography>
        </Box>
    )
}