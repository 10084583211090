import { Box, Toolbar, Typography } from "@mui/material"

const btns = [
    {
        title: 'Play Store',
        link: 'https://play.google.com/store/apps/details?id=mn.infosystems.pharmo&pcampaignid=web_share',
        img: '/images/playstore.png',
    },
    {
        title: 'App Store',
        link: 'https://apps.apple.com/us/app/pharmo-mn/id6502379297',
        img: '/images/appstore.png',
    },
]

export default function DownloadAppButtons({ isMobile = null }) {
    return (
        <>
            <Toolbar />
            { isMobile &&
                <Typography
                    sx={{
                        fontSize: '14px',
                        p: 2,
                        textAlign: 'center'
                    }}
                >
                    Гар утасны аппликейшнээр нэвтрээрэй.
                </Typography>
            }
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center' }}>
                {
                    btns.map((btn, i) => (
                        <a key={i} href={btn.link} target='_blank' rel='noreferrer'>
                            <img 
                                src={btn.img} 
                                alt={btn.title} 
                                style={{ width: '120px', height: '40px', margin: '5px' }}
                            />
                        </a>
                    ))
                }
            </Box>
        </>
    )
}
