import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Box, ListItem, Typography } from '@mui/material'

export default function CategoryItem({ menuItem, selected, onSelect, indent }) {
    const isMatch = selected?.id === menuItem.id
    const iconColor = isMatch ? 'secondary.main' : 'primary.dark'
    const iconStyle = { fontSize: '18px', fontWeight: 'bolder', color: iconColor }
    const handleClick = () => {
        if (menuItem.id !== selected?.id) {
            onSelect({
                ...menuItem,
                expanded : true,
            })
        } else {
            onSelect({
                ...selected,
                expanded: !selected?.expanded,
            })
        }
    }
    
    return (
        <ListItem
            sx={{
                m: 0,  p: 0, cursor: 'pointer',
                paddingLeft: `${indent}px`,
                color: selected?.id === menuItem.id ? 'secondary.light': 'black',
            }}
            onClick={handleClick}
        >
            <Box>
                {
                    menuItem?.children?.length > 0
                        ?   selected?.id === menuItem.id 
                                ?   selected.expanded
                                    ?   <ExpandMore sx={{ ...iconStyle }}/> 
                                    :   <ChevronRight sx={{ ...iconStyle }}/>
                            : menuItem?.childs?.includes(selected?.id)
                                ?   <ExpandMore sx={{ ...iconStyle }}/> 
                                :   <ChevronRight sx={{ ...iconStyle }}/>
                        : <ExpandMore  sx={{ color: 'transparent', fontSize: '18px', fontWeight: 'bolder' }} />
                }
            </Box>
            <Typography
                variant="body1" 
                sx={{
                    fontSize: isMatch ? '13.5px' : '13px', '&:hover': { color: 'secondary.light' },
                    p: '1px', fontWeight: 600
                }}
                color={isMatch ? 'secondary.light' : 'primary.light'}
            >{menuItem.name}</Typography>
        </ListItem>
    )
}
