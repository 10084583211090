import { createContext, useContext, useEffect, useState } from 'react'
import { getToast } from '../auth/helpers/ToastAlert'
import { isTokenValid } from '../../utils/functions'
import CssBaseline from '@mui/material/CssBaseline'
import { Outlet, useLocation } from 'react-router'
import { Container, Paper } from '@mui/material'
import { boardHeight, menus } from './utils'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import AdminNav from './AdminNav'
import Drawer from './Drawer'

export const staffForbidden = ['Promotion', 'Бараа', 'Харилцагч', 'Тохиргоо']
export const tabNames = ['Захиалга', 'Promotion', 'Харилцагч', 'Бараа', 'Барааны ангилал', 'Түгээлт', 'Тайлан', 'Тохиргоо', 'Лог']
const roleTabs = {
	A: tabNames,
	B: tabNames.filter(t => t !== 'Тохиргоо'),
	S: ['Захиалга'],
	D: ['Түгээлт', 'Захиалга'],
	P: ['Захиалга']
}

const AdminCtx = createContext()
export const useAdminCtx = () => useContext(AdminCtx)

export default function Admin() {
	const { role } = isTokenValid()
	// filter menus
	const menus2 = menus.filter(m => roleTabs[role]?.includes(m.name))

	const tabs = menus2.map(m => m.name)

	const [tab, setTab] = useState(tabs[0] || null)
	const [open, setOpen] = useState(true)
	const toggleDrawer = () => { setOpen(!open) }

	const loc = useLocation()
	useEffect(() => {
		if (loc?.pathname) {
			switch(loc.pathname) {
				case '/admin/orders': setTab('Захиалга')
					break
				case '/admin/promotion': setTab('Promotion')
					break
				case '/admin/customers': setTab('Харилцагч')
					break
				case '/admin/products/list': setTab('Бараа')
					break
				case '/admin/products/category': setTab('Бараа')
					break
				case '/admin/shipment': setTab('Түгээлт')
					break
				case '/admin/report': setTab('Тайлан')
					break
				case '/admin/settings': setTab('Тохиргоо')
					break
				case '/admin/system_log': setTab('Лог')
					break
			}
		}
	}, [loc?.pathname])

	// msg
    const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
    const alertWarning = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }
    const alertSuccess = () => { setMsg({ m: 'Амжилттай!', s: 'success' }) }

	// tab name or page title
	const title = tab ? `${tab}` : 'Pharmo'
	useEffect(() => {
		if (tab) { document.title = title }
	}, [tab])

	return (
		<AdminCtx.Provider 
			value={{ 
				msg, setMsg, alertWarning, alertSuccess,
			}}
		>
			<title>{title}</title>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				{getToast(Boolean(msg?.m), msgOff, msg?.m, msg?.s, 2000)}
				<AdminNav open={open} toggleDrawer={toggleDrawer} />
				<Drawer setTab={setTab} tab={tab} open={open} toggleDrawer={toggleDrawer} menus={menus2} />
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === 'light'
								?	theme.palette.grey[100]
								:	theme.palette.grey[900],
						flexGrow: 1, height: '100vh', overflow: 'auto'
					}}
				>
					<Toolbar />
					<Container maxWidth="100%">
						<Paper sx={{ borderRadius: 1, boxShadow: 3, padding: '4px', height: boardHeight }}>
							<Outlet />
						</Paper>
					</Container>
				</Box>
			</Box>
		</AdminCtx.Provider>
	)
}