import { Dialog, IconButton, InputAdornment, TableCell, TextField, useTheme } from '@mui/material'
import { useProductListCtx } from '../../../..'
import { Close } from '@mui/icons-material'
import FieldInput from './FieldInput'
import { useState } from 'react'

export default function ByField({ field, label }) {
    const { filters, setFilters } = useProductListCtx()
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const toOpen = () => { setOpen(true) }
    const toClose = () => { setOpen(false) }
    const val = filters?.find(f => f.k === field)?.v
    const handleClear = (e) => { setFilters(prev => ([ ...prev.filter(f => f.k !== field)])); e.stopPropagation() }

    return (
        <TableCell key={field} sx={{ p: 0, px: '2px' }}>
            <TextField
                size='small'
                placeholder={label}
                value={val||label}
                sx={{
                    flex: 1, width: '100%', m: 0,
                    '&:hover fieldset': { borderColor: theme.palette.primary.light },
                    '& fieldset': { borderColor: theme.palette.primary.light, borderRadius: 0, borderWidth: val ? 3 : 1 },
                    '& .MuiInputBase-input::placeholder': { color: theme.palette.primary.light, fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                inputProps={{ style: { padding: '3px 5px', fontSize: '14px', color: theme.palette.primary.light, cursor: 'pointer' } }}
                InputProps={{ 
                    readOnly: true,
                    endAdornment: (<InputAdornment>
                                    <Close
                                        sx={{ 
                                            fontSize: '18px', color: theme.palette.primary.light,
                                            '&:hover': { cursor: 'pointer' },
                                            visibility: Boolean(val) ? 'visible' : 'hidden'
                                        }} 
                                        onClick={handleClear}
                                    />
                                </InputAdornment>)
                }}
                autoComplete='off'
                onClick={toOpen}
            />
            <Dialog open={open} onClose={toClose}>
                <IconButton onClick={toClose} sx={{ color: theme.palette.primary.light, display: 'flex', alignSelf: 'center' }}>
                    <Close />
                </IconButton>
                <FieldInput filters={filters} setFilters={setFilters} toClose={toClose} field={field} />
            </Dialog>
        </TableCell>
    )
}
