import RegistrationForm from "./RegistrationForm"
import { useLocation } from "react-router"

export default function CompleteRegistration() {
    const loc = useLocation()
    const email = loc?.state?.email
    //const rf = useRef(null)
    //console.log("RF: ", rf?.current?.getBoundingClientRect()?.width)
    return (
        <RegistrationForm email={email} />
    )
}
