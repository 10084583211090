import { Box, Divider } from '@mui/material'
import PromosBtn from './PromosBtn'

const StyledBox = ({ sf, icon, onClick, children }) => (
    <Box
        sx={{
            p: '2px 6px', border: 1, borderRadius: 1, boxShadow: 3, borderColor: 'secondary.light', 
            color: sf ? 'white' : 'secondary.light', bgcolor: sf ? 'secondary.light' : 'white',
            '&:hover': { cursor: 'pointer', bgcolor: 'secondary.light', color: 'white' }, 
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            transition: 'ease-in-out 0.2s', fontSize: '13px'
        }}
        onClick={onClick}
    >{icon}&nbsp;&nbsp;{children}</Box>
)

export default function Featured({ sf, setSf, choices }) {
    const handleClick = (id) => { sf === id ? setSf(null) : setSf(id) }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', p: '2px', justifyContent: 'center', gap: 3, width: '100%' }}>
            {
                choices?.map(choice => (
                    <StyledBox 
                        key={choice.id}
                        sf={sf === choice.id}
                        icon={choice.icon}
                        onClick={() => handleClick(choice.id)}
                    >{choice.text}</StyledBox>
                ))
            }
            <Divider orientation='vertical' />
            <PromosBtn selected={sf === 4} setSf={setSf} />
        </Box>
    )
}
