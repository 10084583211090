import AllocationMapProvider from "../components/admin/tabs/Order/Orders/AllocationMap"
import DownloadAppsPage from "../components/home/DownloadAppButtons/DownloadAppsPage"
import DeliveryMapProvider from "../components/admin/tabs/Shipment/DeliveryMap"
import CompleteRegistration from "../components/auth/CompleteRegistration"
import PrivacyPolicy from "../components/home/footer/PrivacyPolicy"
import IsValidAdmin, { IsValid, IsValidR } from "./utils"
import LandingPage from "../components/home/LandingPage"
import { createBrowserRouter } from "react-router-dom"
import Confirmation from "../components/confirmation"
import { confirmRoutes } from "./confirmRoutes"
import ErrorPage from "./errorPage/ErrorPage"
import { adminRoutes } from "./adminRoutes"
import { homeRoutes } from "./homeRoutes"
import Seller from "../components/seller"
import Admin from "../components/admin"
import Home from '../components/home'
import SignInUpBox from "../components/auth/SignInUpBox"
import SignIn from "../components/auth/SignInUpBox/SignIn"
import SignUp from "../components/auth/SignInUpBox/SignUp"

export const mainRoute = createBrowserRouter([
    {
        path: '/',
        element: <IsValid><Home /></IsValid>,
        errorElement: <ErrorPage />,
        children: homeRoutes
    },
    {
        path: '/home',
        element: <LandingPage />,
    },
    {
        path: '/signin',
        element: <SignInUpBox><SignIn /></SignInUpBox>,
    },
    {
        path: '/signup',
        element: <SignInUpBox><SignUp /></SignInUpBox>,
    },
    {
        path: '/complete_registration',
        element: <SignInUpBox><CompleteRegistration /></SignInUpBox>,
    },
    {
        path: '/privacy_policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/download_apps',
        element: <DownloadAppsPage />,
    },
    {
        path: '/admin',
        element: <IsValidAdmin><Admin /></IsValidAdmin>,
        children: adminRoutes
    },
    {
        path: '/seller',
        element: <Seller />,
    },
    {
        path: '/admin/shipment_progress',
        element: <IsValidAdmin><DeliveryMapProvider /></IsValidAdmin>,
    },
    {
        path: '/admin/to_shipment',
        element: <IsValidAdmin><AllocationMapProvider /></IsValidAdmin>,
    },
    {
        path: process.env.REACT_APP_CR,
        element: <IsValidR><Confirmation /></IsValidR>,
        children: confirmRoutes
    },
])