import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useState } from "react"

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function SalesTableChart ({ tableData, labels, titleText = 'Борлуулалтын график', monthOrQuarter = 'month' }) {
	// Labels and data for the chart
	const countData = tableData.map((data) => data.count)
	const totalData = tableData.map((data) => data.total)

	// Chart.js data and options
	const chartData = {
		labels,
		datasets: [
			{
				label: "Тоо",
				data: countData,
				backgroundColor: "rgba(75, 192, 192, 0.6)",
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 1,
			},
			{
				label: "Дүн (₮)",
				data: totalData,
				backgroundColor: "rgba(255, 99, 132, 0.6)",
				borderColor: "rgba(255, 99, 132, 1)",
				borderWidth: 1,
			},
		],
	}

	const [showTotal, setShowTotal] = useState(false)	// Show total sales

	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: titleText,
			},
			datalabels: {
				// Plugin configuration for data labels
				display: showTotal ? true : false,
				color: "black",
				anchor: "end",
				align: "top",
				rotation: -25,
				formatter: (value) => value
			},
		},
		scales: {
			x: { stacked: false },
			y: { beginAtZero: true },
		},
	}

	return (
		<div style={{ width: "800px", height: "500px" }}>
			<Bar data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
			<p style={{ textAlign: 'center' }}>
				<FormControlLabel
					label={<Typography variant="caption" sx={{ color: '#6d6d6d' }}>Нийт дүнгүүдийг харуулах</Typography>} 
					control={
						<Checkbox
							size="small"
							checked={showTotal}
							onChange={() => { setShowTotal((prev) => !prev) }}
						/>
					}
				/>
			</p>
		</div>
	)
}
