import { Box, Button, Dialog, IconButton, Tooltip, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useState } from 'react'

export default function DeleteBtn({ onYes = null, onClose = null, isShow = true }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { 
        if (onClose) { onClose() }
        setOpen(false) 
    }
    const handleYes = () => {
        if (onYes) { onYes() }
        handleClose()
    }

    return (
        <div>
            <Tooltip title='Устгах' placement='right-end'>
                <IconButton 
                    onClick={handleOpen} 
                    sx={{ 
                        visibility: isShow ? 'visible' : 'hidden', 
                        '&:hover': { color: 'secondary.light' }, transition: 'all 0.1s linear'
                    }}
                ><Delete /></IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', p: 3 }}>
                    <Typography sx={{ mb: 2 }}>Устгахдаа итгэлтэй байна уу!</Typography>
                    <Box>
                        <Button
                            variant='contained' 
                            size='small'
                            sx={{ mr: 3 }}
                            onClick={ handleYes }
                        >Тийм</Button>
                        <Button 
                            variant='contained' 
                            size='small' 
                            sx={{ 
                                backgroundColor: 'secondary.light', 
                                '&:hover': { backgroundColor: 'secondary.main' } 
                            }}
                            onClick={ handleClose }
                        >Үгүй</Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}
