import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'

const REPORT_TYPES = [
    { value: 'total_sales', label: 'Нийт борлуулалт' },
    { value: 'by_customer', label: 'Харилцагчаар (MACS)' },
    { value: 'by_pharmo_user', label: 'Харилцагчаар (Pharmo)' },
    { value: 'by_seller', label: 'Борлуулагчаар' },
    { value: 'by_products', label: 'Нийт бараагаар' },
    { value: 'by_product', label: 'Тухайн бараагаар' },
]

export default function ReportType({ reportType, setReportType }) {
    const handleChange = (e) => { setReportType(e.target.value) }

    return (
        <Box sx={{ width: 'fit-content' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Төрөл</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reportType}
                    label="Төрөл"
                    onChange={handleChange}
                    size='small'
                >
                    {
                        REPORT_TYPES.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    )
}
