import { Box, Dialog, IconButton, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { AccountCircle, Close } from '@mui/icons-material'
import { isTokenValid } from '../../../../utils/functions'
import { getStaffs } from '../../../../api/staff'
import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'

const roles = {
	A: 'Админ',
	S: 'Борлуулагч',
	D: 'Түгээгч',
	B: 'Захиалгын менежер',
	P: 'Бэлтгэгч'
}

export default function StaffInfo({ user_id }) {
    const { role, name } = isTokenValid()
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const [staff, setStaff] = useState(null)
    const handleClose = () => { setOpen(false); setStaff(null) }
    useEffect(() => {
		if (open && user_id && role !== 'A') {
            getStaffs(user_id)
                .then(r => { setStaff(r?.data) })
                .catch(_ => {})
        }
	}, [open, user_id, role])

    return (
        <>
            <Box
                onClick={() => { setOpen(true) }}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    '&:hover': { cursor: 'pointer' }
                }}
            >
                <AccountCircle sx={{ color: theme.palette.primary, fontSize: '60px', mr: 1 }} />
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        '&:hover': { cursor: 'pointer' }
                    }}
                >
                    {
                        name && 
                            <Typography sx={{ color: 'primary.light', textAlign: 'center', fontSize: '15px', fontWeight: 600 }}>
                                {name}
                            </Typography>
                    }
                    <Typography sx={{ color: 'secondary.light', textAlign: 'center', fontSize: '13px', fontWeight: 600, fontStyle: 'italic' }}>
                        {name ? '(' : ''}
                        {roles?.[role]||''}
                        {name ? ')' : ''}
                    </Typography>
                </Box>
            </Box>
            <Dialog open={open && staff} onClose={handleClose}>
                <Box sx={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClose}><Close sx={{ color: theme.palette.primary }}/></IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                    <TableContainer sx={{ mb: 2 }}>
                        <Table sx={{ '& > tr > td': { p: '2px 8px' } }}>
                            <TableRow><TableCell>Нэр</TableCell><TableCell sx={{ color: theme.palette.primary, fontWeight: 'bold' }}>{staff?.name}</TableCell></TableRow>
                            <TableRow><TableCell>Имэйл</TableCell><TableCell sx={{ color: theme.palette.primary, fontWeight: 'bold' }}>{staff?.email}</TableCell></TableRow>
                            <TableRow><TableCell>РД</TableCell><TableCell sx={{ color: theme.palette.primary, fontWeight: 'bold' }}>{staff?.rd}</TableCell></TableRow>
                            <TableRow><TableCell>Утас</TableCell><TableCell sx={{ color: theme.palette.primary, fontWeight: 'bold' }}>{staff?.phone}</TableCell></TableRow>
                            <TableRow><TableCell>Ажил үүрэг</TableCell><TableCell sx={{ color: theme.palette.primary, fontWeight: 'bold' }}>{staff?.role}</TableCell></TableRow>
                        </Table>
                    </TableContainer>
                </Box>
            </Dialog>
        </>
    )
}
