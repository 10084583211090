import { IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { removeFromBasket } from '../../../../api/basket'
import { useAuthCtx } from '../../../../context/AuthCtx'
import { toPrice } from '../../../../utils/functions'
import { HighlightOff } from '@mui/icons-material'
import UpdateQtyInput from './UpdateQtyInput'

export default function Row({ item, idx }) {
    const { setBasket, toasWait } = useAuthCtx()
    const itemTotalPrice = item?.qty * item?.price

    const handleRemove = () => {
        if (item?.id) {
            removeFromBasket(item.id)
                .then(res => { setBasket(res?.data) })
                .catch(e => { console.log("ERRrm: ", e); toasWait() })
        }
    }

    return (
        <TableRow
            sx={{
                '& > td': {
                    py: '2px', 
                    px: '6px', 
                    textAlign: 'center',
                    fontSize: '15px', 
                },
                '&:hover': { backgroundColor: '#f5f5f5' }
            }}
        >
            <TableCell>{idx + 1}</TableCell>
            <TableCell sx={{ textAlign: 'left !important' }}>{item?.name||'-'}</TableCell>
            <TableCell sx={{ textAlign: 'right !important', pr: '30px !important', fontWeight: 600 }}>{toPrice(item?.price||'-')}</TableCell>
            <TableCell>
                <UpdateQtyInput item={item} setBasket={setBasket} />
            </TableCell>
            <TableCell sx={{ textAlign: 'right !important', pr: '30px !important', fontWeight: 600 }}>{toPrice(itemTotalPrice)}</TableCell>
            <TableCell>
                <Tooltip title={<Typography fontSize='14px'>Захиалгаас хасах</Typography>} placement='right'>
                    <IconButton onClick={handleRemove} sx={{ p: '4px' }}>
                        <HighlightOff fontSize='15px' sx={{ color: 'primary.light', '&:hover': { color: 'secondary.light' } }} />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}
