import YearMonthSelector from './YearMonthSelector'
import { Box } from '@mui/material'

export default function DateSelector({ currentYear, ym1, setYm1, ym2, setYm2, reportType }) {
	return (
		<Box sx={{ display: 'flex', gap: 1, flexDirection: 'row' }}>
			<YearMonthSelector 
				yearMonth={ym1}
				setYearMonth={setYm1}
				currentYear={currentYear}
			/>
			{ ['total_sales', 'by_product'].includes(reportType)  &&
				<YearMonthSelector 
					yearMonth={ym2}
					setYearMonth={setYm2}
					currentYear={currentYear}
					isSecond={true}
				/>
			}
		</Box>
	)
}
