import { TableCell, TableRow, Tooltip, useTheme } from "@mui/material"
import { ISCOMPANIES } from "../../../../../../utils/constants"
import TextFieldFilter from './FilterCells/TextFieldFilter'
import { FilterAlt } from "@mui/icons-material"
import DropCell from '../DropCell'
import ByField from './ByField'

export default function FilterRow({ filtering, setFiltering }) {
  const theme = useTheme()
  return (
    <TableRow
      sx={{
        "& > td": {
          p: "2px ",
          borderBottom: "1px solid gray",
          borderBottomColor: theme.palette.primary.light,
        },
      }}
    >
      <TableCell align="center" sx={{ p: "2px" }}>
        <Tooltip title="Шүүж хайх мөр" placement="right">
          <FilterAlt sx={{ color: theme.palette.secondary.light, fontSize: "20px", margin: 0 }} />
        </Tooltip>
      </TableCell>
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__name" />
      <TableCell />
      <DropCell field='is_company' choices={ISCOMPANIES} filters={filtering} setFilters={setFiltering} />
      <ByField field='debt' label={'Нийт өр'} filters={filtering} setFilters={setFiltering} />
      <ByField field='credit' label={'Зээлийн лимит'} filters={filtering} setFilters={setFiltering} />
      <ByField field='bad_cnt' label={'Найдваргүй тоо'} filters={filtering} setFilters={setFiltering} />
      <TableCell />
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__email" />
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__rd" />
      <ByField field='orders_cnt' label={'Захиалга тоо'} filters={filtering} setFilters={setFiltering} />
      <ByField field='orders_sum' label={'Захиалга дүн'} filters={filtering} setFilters={setFiltering} />
      <ByField field='created' label={'Огноо'} filters={filtering} setFilters={setFiltering} />
    </TableRow>
  )
}
