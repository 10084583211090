import { getToast } from '../../../auth/helpers/ToastAlert'
import { Box, IconButton, Typography } from '@mui/material'
import { StyledBadge } from '../../../helpers/styledBadge'
import { useAuthCtx } from '../../../../context/AuthCtx'
import { useLocation, useNavigate } from 'react-router'
import { ShoppingCart } from '@mui/icons-material'
import DropMenu from '../../../helpers/DropMenu'
import { useEffect, useState } from 'react'
import BasketDetail from './BasketDetail'

export default function Basket () {
    const { basket, setBasket } = useAuthCtx()

    const navi = useNavigate()
    const loc = useLocation()

    const [empty, setEmpty] = useState(false)
    const emptyOff = () => { setEmpty(false) }

    const goToOrder = () => {
        if (basket?.items?.length === 0) {
            setEmpty(true)
        } else { navi('/order') }
    }

    const [msg, setMsg] = useState(null)

    useEffect(() => {
        if (basket?.items?.length === 0 && loc?.pathname === '/order') { navi('/') }
    }, [basket])

    return (
        <>
            {getToast(Boolean(msg), () => setMsg(null), msg?.m, msg?.s, 2000)}
            {getToast(empty, emptyOff, 'Сагсанд бараа нэмнэ үү!', 'warning', 1500)}
            <DropMenu
                title={
                    <IconButton onClick={goToOrder}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'  }}>
                            <StyledBadge 
                                color='secondary' 
                                showZero={false} 
                                badgeContent={
                                    basket?.totalCount > 0
                                        ?   <Typography color='white' fontSize='14px'>{basket.totalCount}</Typography>
                                        :   0
                                    }
                            >
                                <ShoppingCart sx={{ color: "white", '&:hover': { color: '#e2e2e2' }, fontSize: 30 }} />
                            </StyledBadge>
                        </Box>
                    </IconButton>
                }
                detail={
                <BasketDetail 
                    basket={basket} 
                    setBasket={setBasket}
                    goToOrder={goToOrder}
                    setMsg={setMsg}
                />
                }
                top={40}
                left={-150}
            />
        </>
    )
}