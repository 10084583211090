import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material"

const startYear = 2022
export default function YearMonthSelector({ yearMonth, setYearMonth, currentYear, isSecond }) {
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index)
	const months = Array.from({ length: 12 }, (_, i) => i + 1)

	const yearKey = isSecond ? 'year2' : 'year'
	const handleYearChange = (event) => { setYearMonth(prev => ({ ...prev, [yearKey]: event.target.value })) }
	const monthKey = isSecond ? 'month2' : 'month'
	const handleMonthChange = (event) => { setYearMonth(prev => ({ ...prev, [monthKey]: event.target.value })) }
	return (
			<Box display="flex" alignItems="center" gap={'5px'}>
				<FormControl variant="outlined" size="small">
					<InputLabel>Он</InputLabel>
					<Select
						value={yearMonth?.[yearKey]}
						onChange={handleYearChange}
						label="Он"
					>
						{years.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
					</Select>
				</FormControl>
				<FormControl variant="outlined" size="small">
					<InputLabel>Сар</InputLabel>
					<Select
						value={yearMonth?.[monthKey]}
						onChange={handleMonthChange}
						label="Сар"
					>
						{months.map((m) => <MenuItem key={m} value={m}>{m}</MenuItem>)}
					</Select>
				</FormControl>
			</Box>
		)
}
