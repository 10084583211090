import { Box, InputAdornment, MenuItem, TableCell, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import DropMenu from '../../../../helpers/DropMenu'
import { Close } from '@mui/icons-material'
import { keys } from 'underscore'
import { useState } from 'react'

export default function DropCell({ field, choices, filters, setFilters }) {
    const theme = useTheme()
    const value = filters?.find(f => f.k?.startsWith(field))?.v
    const val2 = value === 'true' ? 'Очиж авах' : null
    const handleSelect = (newVal) => {
        setFilters(prev => {
            const f = { k: field, v: newVal, o: '=' }
            if (prev?.length) {
                if (prev.map(p => p.k).includes(field)) {
                    return prev.map(p => p?.k === field ? f : p)
                } else { return [...prev, f] }
            }
            return [f]
        })
    }

    const handleNoAddress = () => {
        setFilters(prev => {
            const f = { k: 'address__isnull', v: 'true', o: '=' }
            if (prev?.length) {
                if (prev.map(p => p.k).includes(field)) {
                    return prev.map(p => p?.k === field ? f : p)
                } else { return [...prev, f] }
            }
            return [f]
        })
    }
    const clearSelect = () => { setFilters(prev => prev?.filter(p => !p.k.startsWith(field)) ) }

    // hover
    const [hover, setHover] = useState(false)

    return (
        <TableCell>
            <DropMenu
                title={<Box sx={{ display: 'flex', flexDirection: 'row', p: '4px' }}>
                            <Tooltip title={<Typography fontSize={'14px'}>{val2??choices?.[value]}</Typography>} placement='top' open={Boolean(value && hover)}>
                                <TextField
                                    onMouseEnter={() => { setHover(true) }}
                                    onMouseLeave={() => { setHover(false) }}
                                    placeholder={val2??choices?.[value]??'Сонгох'}
                                    InputProps={{
                                        endAdornment: (<InputAdornment>
                                                            <Close 
                                                                sx={{ 
                                                                    fontSize: '18px', color: theme.palette.primary.light, 
                                                                    '&:hover': { cursor: 'pointer' },
                                                                    display: Boolean(value) ? 'block' : 'none'
                                                                }} 
                                                                onClick={clearSelect}
                                                            />
                                                        </InputAdornment>)
                                    }}
                                    inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
                                    sx={{
                                        '& fieldset': { borderColor: theme.palette.primary.light, borderRadius: 0 },
                                        '&:hover fieldset': { borderColor: theme.palette.primary.light },
                                        '& .MuiInputBase-input::placeholder': { color: theme.palette.primary.light, fontSize: '14px', opacity: 1, ml: '5px' },
                                        '& .MuiInputBase-input': { 'caretColor': 'transparent' }
                                    }}
                                    size='small'
                                />
                            </Tooltip>
                        </Box>}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                            {keys(choices).map(k => <MenuItem key={k} sx={{ fontSize: '14px', color: theme.palette.primary.light }} onClick={() => handleSelect(k)}>
                                                        {choices[k]}
                                                    </MenuItem>)}
                                { field === 'address' && <MenuItem sx={{ fontSize: '14px', color: theme.palette.primary.light }} onClick={handleNoAddress}>Очиж авах</MenuItem> }
                            </Box>}
                left={4}
                top={32}
            />
        </TableCell>
    )
}
