import { Box, Button, TextField, useTheme } from '@mui/material'
import { PushPin } from '@mui/icons-material'

export default function Inputs({ value, setValue, doFilter, error, setIsPinned, isPinned }) {
    const theme = useTheme()
    const handleEnter = (e) => { if (e?.code === 'Enter') { doFilter() } }
    const handlePin = () => { setIsPinned(!isPinned) }

    return (
        <Box sx={{ backgroundColor: 'white', boxShadow: 8, p: 3, width: '350px'}}>
            <Box 
                sx={{ 
                    position: 'absolute', top: 1, left: 3, 
                    '&:hover': { cursor: 'pointer' }
                }}
                onClick={handlePin}
            >
                <PushPin sx={{ color: isPinned ? theme.palette.secondary.light : theme.palette.primary.light }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start'  }}>
                <TextField
                    size='small'
                    placeholder='Нэр эсвэл Регистрийн дугаар'
                    value={value||''}
                    onChange={e => { setValue(e?.target?.value) }}
                    sx={{
                        flex: 1,
                        '& fieldset': { borderColor: theme.palette.primary.light, borderRadius: 0 },
                        '&:hover fieldset': { borderColor: theme.palette.primary.light },
                        '& .MuiInputBase-input::placeholder': { color: theme.palette.primary.light, fontSize: '13px', opacity: 1, ml: '5px' },
                    }}
                    inputProps={{ style: { padding: '3px 5px', fontSize: '13px', color: theme.palette.primary.light } }}
                    onKeyDown={handleEnter}
                    error={Boolean(error)}
                    helperText={Boolean(error) ?  error :  ''}
                    autoComplete='off'
                />
                <Button 
                    size='small' variant='contained' sx={{ p: '2px', borderRadius: 0, ml: 1, fontSize: '13px' }}
                    onClick={doFilter}
                >Хайх</Button>
            </Box>
        </Box>
    )
}
