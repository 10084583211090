import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import TablePaginationFooter from '../../../../common/TablePaginationFooter'
import { paperStyle, scrollStyle } from '../../../../../utils/constants'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { createContext, useContext, useState } from 'react'
import { getPromotions } from '../../../../../api/promo'
import { useQuery } from 'react-query'
import Filter from './Filter'
import Promo from './Promo'
import Row from './Row'

const headCellStyle = { backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' }
const headCols = ['Урамшууллын нэр', 'Урамшууллын код', 'Урамшууллын төрөл', 'Дуусах огноо', 'Бэлэгтэй эсэх']

const PromosCtx = createContext(null)
export const usePromosCtx = () => useContext(PromosCtx)
export default function Promos({ supplierId }) {
    const theme = useTheme()
    const rppOptions = [10, 25, 50]
    const [page, setPage] = useState(1)
    const handlePage = (page) => { setPage(page) }
    const [pageSize, setPageSize] = useState(10)
    const handlePageSize = (pageSize) => { setPageSize(pageSize) }

    const [filters, setFilters] = useState([])
    const { data } = useQuery(['get_promos', filters, pageSize, page, supplierId], getPromotions)
    const { count, results } = data?.data || {}

    const [selectedPromo, setSelectedPromo] = useState(null)
    
    // alert
    const [msg, setMsg] = useState(null)
    
    return (
        <PromosCtx.Provider value={{ setMsg }}>
            {getToast(Boolean(msg), () => setMsg(null), msg?.m, msg?.s )}
            <TableContainer sx={{ width: '80%', ...paperStyle, maxHeight: '80vh', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
                <Table aria-label="simple table" sx={{ fontSize: '12px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...headCellStyle, textAlign: 'center', p: '2px' }}>№</TableCell>
                            {
                                headCols.map(f => <TableCell sx={{ ...headCellStyle, p: '2px', textAlign: 'center' }}>{f}</TableCell>)
                            }
                        </TableRow>
                    </TableHead>
                    <Filter filters={filters} setFilters={setFilters} />
                    <TableBody>
                        {
                            results?.length > 0
                                ?   results?.map(
                                        (row, idx) => <Row key={row?.id} row={row} idx={idx} page={page} pageSize={pageSize} setSelectedPromo={setSelectedPromo} />
                                    )
                                :   <TableRow>
                                        <TableCell colSpan={6} align='center' sx={{ height: '100px' }}>
                                            <Typography fontSize='16px' fontWeight='bold' sx={{ color: theme.palette.primary.light }}>Үр дүн олдсонгүй!</Typography>
                                        </TableCell>
                                    </TableRow>
                        }
                    </TableBody>
                    {
                        results?.length > 0 && 
                            <TablePaginationFooter
                                rppOptions={rppOptions}
                                count={count}
                                page={page}
                                handlePage={handlePage}
                                pageSize={pageSize}
                                handlePageSize={handlePageSize}
                            />
                    }
                </Table>
            </TableContainer>
            {selectedPromo && <Promo promo={selectedPromo} setSelectedPromo={setSelectedPromo} />}
        </PromosCtx.Provider>
    )
}
