import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { getShipments } from '../../../../../api/shipment'
import TablePages from '../../../../common/TablePages'
import { useEffect, useState } from 'react'
import ShipmentRow from './ShipmentRow'
import { useQuery } from 'react-query'
import FilterRow from './FilterRow'

const cols = [ '№', '', 'Түгээгч', 'Огноо', 'Эхэлсэн цаг', 'Дууссан цаг', 'Хугацаа', 'Хүргэлтийн тоо', 'Хүргэсэн тоо', 'Явцын дүн', 'Захиалгын тоо', 'Зарлага']
export default function ShipmentHistory({ isDelman, delmanNames, alertWarning, msg, setMsg }) {
    const [filters, setFilters] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [count, setCount] = useState(0)
    const [shipments, setShipments] = useState([])
    const today = new Date().toISOString().slice(0, 10)
    const notToday = shipments?.filter(s => s.createdOn !== today)
    const getData = (data) => { setShipments(data?.data?.results||[]); setCount(data?.data?.count||0) }
    const { isFetching, refetch: refetchShipmentHistory } = useQuery(['shipmentHistory', filters, page, pageSize], getShipments, { onError: alertWarning, retry: false, onSuccess: (data) => { getData(data) } } )
    useEffect(() => { setPage(1) }, [filters])
    //console.log("S: ", shipments)

    const [expanded, setExpanded] = useState(isDelman||null)

    return (
        <TableContainer
            sx={{
                p: 1,
                mt: 2,
                boxShadow: 3,
                maxHeight: '80%',
                ...scrollStyle,
                '&::-webkit-scrollbar': { width: 8 },
            }}
        >
            <Table>
                <TableHead>
                    <TableRow sx={{ '& > th': { color: 'white', textAlign: 'center', backgroundColor: 'secondary.light', borderRight: '1px solid white', p: '2px 4px' } }}>
                        {cols.map(c => <TableCell key={c}>{(isDelman && c === '№') ? '': c}</TableCell>)}
                    </TableRow>
                    {
                        notToday?.length > 0 &&
                            <FilterRow filters={filters} setFilters={setFilters} delmanNames={delmanNames} isDelman={isDelman} />
                    }
                </TableHead>
                <TableBody>
                    {
                        isFetching
                            ?  
                                <TableRow>
                                    <TableCell colSpan={cols.length} sx={{ textAlign: 'center', height: '200px' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            :   notToday?.length > 0
                                    ?   notToday?.map((s, idx) =>
                                                    <ShipmentRow 
                                                        key={s.id} 
                                                        idx={idx}
                                                        shipment={s}
                                                        delmanNames={delmanNames}
                                                        isDelman={isDelman}
                                                        alertWarning={alertWarning}
                                                        msg={msg}
                                                        setMsg={setMsg}
                                                        expanded={expanded}
                                                        setExpanded={setExpanded}
                                                        refetchShipmentHistory={refetchShipmentHistory}
                                                    />
                                                )
                                    :   <TableRow sx={{ m: 2 }}>
                                            <TableCell colSpan={cols?.length} sx={{ textAlign: 'center' }}>Одоогоор түгээлт байхгүй!</TableCell>
                                        </TableRow>
                    }
                </TableBody>
                    {
                        notToday?.length > 0 &&
                            <TablePages
                                count={count}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                colSpan={cols.length}
                            />
                    }
            </Table>
        </TableContainer>
    )
}