import { updateBasket } from '../../../../../api/basket'
import { TextField } from '@mui/material'
import { useState } from 'react'

export default function UpdateQtyInput ({ item, setBasket }) {
    const [qty, setQty] = useState(item?.qty||1)
    const [value, setValue] = useState(qty)
    const [err, setErr] = useState(null)

    const handleValue = (e) => {
        const newVal = e?.target?.value
        setValue(newVal)
        if (!newVal) { setErr('Утга оруулна уу!') }
        else if (isNaN(newVal)) { setErr('Тоон утга оруулна уу!') }
        else if (newVal <= 0) { setErr('1-с багагүй утга оруулна уу!') }
        else { setQty(newVal); setErr(null) }
    }

    const handleUpdate = () => {
        if (!err) {
            updateBasket({ product_id: item?.product_id, qty })
                .then(res => {
                    const d = res?.data
                    if (d?.available_qty) {
                        setErr('Боломжит үлдэгдэл: ' + d.available_qty)
                    } 
                    else if (d?.id && d?.totalPrice) {
                        setBasket(d)
                    }
                })
                .catch(e => { console.log("ERR: ", e) })
        }
    }

    const handleEnter = (e) => { if (e.code === 'Enter') { handleUpdate() } }

    return <TextField
                size='small'
                sx={{ width: '100px' }}
                value={ value }
                onChange={ handleValue }
                onBlur={ handleUpdate }
                onKeyDown={ handleEnter }
                inputProps={{ style: { textAlign: 'center', fontSize: '14px', fontWeight: 'bold', padding: '2px', } }}
                type='number'
                error={err}
                helperText={err || ''}
            />
}