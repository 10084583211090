import { searchProductByNameOrBarcode } from '../../../../../../../../api/report'
import { scrollStyle } from '../../../../../../../../utils/constants'
import { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box } from '@mui/material'
import { useAdminCtx } from '../../../../../..'
import ListComboBox from './ListComboBox'
import SearchField from './SearchField'
import SearchInput from './SearchInput'
import ProductItem from './ProductItem'
import { useQuery } from 'react-query'

export const rowHeight = '80px'

export default function SimpleSearch({ setSelectedProduct }) {
    const { alertWarning } = useAdminCtx()
    const searchWidth = '400px'
    const [value, setValue] = useState('')
    const [searchKey, setSearchKey] = useState('name')
    const [page, setPage] = useState(1)
    const pageSize = 20

    useEffect(() => { setPage(1) }, [value, searchKey])
    const { data, isFetched, error, isFetching } = useQuery(['search_product_by_name_or_barcode', searchKey, value, page, pageSize ], searchProductByNameOrBarcode, { enabled: Boolean(value), onError: alertWarning })
    const [results, setResults] = useState([])
    //console.log("sp: ", results?.length, isFetching)
    const allCount = data?.data?.count||0
    const currentCount = results?.length||0
    const hasNextPage = allCount > currentCount
    useEffect(() => {
        console.log("f: ", data?.data?.results?.length)
        if (data?.data?.results?.length) {
            const newData = data.data.results
            if (page > 1) { 
                console.log("f2: ", data.data.results)
                setResults([...results, ...newData]) 
            } else {
                console.log("f1: ", data.data.results)
                setResults(data.data.results)
            }
        }
    }, [data])

    useEffect(() => { if (error?.response?.status === 400 && 'barcode' in error.response.data) { setResults([]) } }, [error])

    const [showResults, setShowResults] = useState(false)

    // delayed search
    const [search, setSearch] = useState(null)
    const searchTimeoutRef = useRef(null)
    useEffect(() => {
        if (search?.length >= 3) {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current)
                searchTimeoutRef.current = null
            }
            searchTimeoutRef.current = setTimeout(() => { setValue(search) }, 650)
        } else if (!search) {
            //setShowResults(false)
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current)
                searchTimeoutRef.current = null
            }
            setResults([])
            setSelectedProduct(null)
        }
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [search])

    const inputRef = useRef(null)

    return (
        <Box>
            <Autocomplete
                id="product-select"
                ref={inputRef}
                noOptionsText={'Тохирох бараа олдсонгүй!'}
                open={Boolean(value && showResults)}
                options={results}
                getOptionLabel={(option) => option?.[searchKey]}
                clearOnBlur={false}
                onFocus={() => { setShowResults(true) }}
                onBlur={() => { setShowResults(false) }}
                renderOption={(props, option) => (
                    <ProductItem
                        {...props} 
                        sx={{ p: 0 }} 
                        product={option} 
                        setSelectedProduct={setSelectedProduct}
                    />
                )}
                renderInput={(params) => (
                    <SearchInput
                        params={params}
                        search={search}
                        setSearch={setSearch}
                        searchKey={searchKey}
                        inputRef={inputRef}
                    />
                )}
                ListboxComponent={
                    (props) => <ListComboBox
                        props={props} 
                        hasNextPage={hasNextPage} 
                        setPage={setPage} 
                    />
                }
                ListboxProps={{
                    sx: {
                        width: searchWidth,
                        maxHeight: 490,
                        boxShadow: 50,
                        border: 1,
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 9, height: 9 },
                    }
                }}
                sx={{ width: searchWidth}}
            />
            {/* <SearchField
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                searchWidth={searchWidth}
            /> */}
        </Box>
    )
}